export const calcChanges = (object) => {
  let count = 0
  if (typeof object === 'object' && object !== null) {
    for (const key of Object.keys(object)) {
      if (key === 'attributes' && Object.keys(object[key]).length > 0) {
        count++
      } else {
        count += calcChanges(object[key])
      }
    }
  }
  return count
}

export const splitToBlocks = (bundle, blockSize) => {
  const result = []

  let counter = 0
  let blockBundle = null
  Object.keys(bundle.sites).forEach((siteId) => {
    const siteBundle = bundle.sites[siteId]
    const length = calcChanges(siteBundle)
    let added = false
    if (counter + length > blockSize) {
      if (blockBundle !== null) {
        result.push({
          bundle: blockBundle,
          size: counter,
        })
        counter = 0
        blockBundle = null
      } else {
        result.push({
          bundle: siteBundle,
          size: length,
        })
        added = true
      }
    }

    if (!added) {
      if (blockBundle === null) {
        blockBundle = {
          sites: {},
        }
      }
      blockBundle.sites[siteId] = siteBundle
      counter += length
    }
  })

  if (blockBundle !== null) {
    result.push({
      bundle: blockBundle,
      size: counter,
    })
  }

  return result
}
