import { STATUS_ACTIVE, STATUS_DRAFT } from '../../constants/network'
import {
  CALC_METHOD_NEW_SECTORS,
  CALC_METHOD_REPEATERS,
  CASE_TYPE_B2B,
  CASE_TYPE_B2C,
} from '../../features/bc/constants'
import { empty } from '../../utils/format'

export const askDataWillBeLost = (ask, actionOk, actionBack) => {
  ask(
    actionOk,
    actionBack,
    null,
    {
      title: 'Confirmation',
      messages: [ 'Unsaved changes on the form will be lost' ],
      textYesBtn: 'Ok',
      textNoBtn: 'Cancel',
    },
  )
}

export const confirmNotAllSelected = (confirm, action) => {
  confirm(
    action,
    {
      title: 'Confirmation',
      messages: [
        'Not all BCs are selected!',
        'Do you wish to Continue?',
      ],
      textYesBtn: 'Yes',
      textNoBtn: 'No',
    },
  )
}

export const askSitesErrors = (ask, unknownSites, sitesWithoutSectors, continueAction, cancelAction) => {
  const messages = []
  if (unknownSites && unknownSites.length > 0) {
    messages.push(`'${unknownSites.join(', ')}' not found in the project`)
  }
  if (sitesWithoutSectors && sitesWithoutSectors.length > 0) {
    const dont = sitesWithoutSectors.length === 1 ? 'doesn\'t' : 'don\'t'
    messages.push(`'${sitesWithoutSectors.join(', ')}' ${dont} contain sectors for calculation`)
  }
  ask(
    continueAction,
    cancelAction,
    null,
    {
      title: 'Confirmation',
      messages,
      textYesBtn: 'Back',
      textNoBtn: 'Exit',
      textCancelBtn: null,
    },
  )
}

export const askNotFieldsReady = (ask, fields, actionContinue, actionCancel) => {
  ask(
    actionContinue,
    actionCancel,
    null,
    {
      title: 'Confirmation',
      messages: [
        `'${fields.join(', ')}' are not filled`,
      ],
      textYesBtn: 'Back',
      textNoBtn: 'Exit',
    },
  )
}

export const askSaveBC = (ask, actionSave, actionCancel) => {
  ask(
    actionSave,
    actionCancel,
    null,
    {
      title: 'Confirmation',
      messages: [
        'Do you want to save your BC?',
      ],
      textYesBtn: 'Yes',
      textNoBtn: 'No',
    },
  )
}

export const askShowStatistics = (ask, techData, actionRun, actionCancel, subTitle) => {
  ask(
    actionRun,
    actionCancel,
    null,
    {
      title: 'Statistics',
      subTitle: subTitle || 'Will be sent for calculation',
      messages: techData,
      textYesBtn: 'Run',
      textNoBtn: 'Back',
      allowContentComponent: true,
    },
  )
}

export const errorMessage = (ask, message) => {
  ask(
    empty,
    null,
    null,
    {
      title: 'Error',
      subTitle: '',
      messages: [
        message,
      ],
      textYesBtn: 'Ok',
      textNoBtn: 'Cancel',
      allowContentComponent: true,
    },
  )
}

export const notUniqueMessage = (ask, names, actionClose) => {
  ask(
    actionClose,
    null,
    null,
    {
      title: 'Error',
      messages: [
        `${names?.join(', ')} ${names?.length > 1 ? 'are' : 'is'} already used or does not exist.`,
      ],
      textYesBtn: 'Ok',
      textNoBtn: 'Cancel',
      allowContentComponent: true,
    },
  )
}

export const isBusinessCaseEligibleForCalculation = (siteStatus, caseType, calculationMethod) => {
  if (siteStatus === STATUS_ACTIVE) {
    // Only B2B / NEW_SECTORS is allowed
    if (caseType !== CASE_TYPE_B2B || calculationMethod !== CALC_METHOD_NEW_SECTORS) {
      return false
    }
  } else if (siteStatus === STATUS_DRAFT) {
    // Only B2B / REPEATERS is allowed
    // Any B2C is alowed
    if (caseType !== CASE_TYPE_B2C && calculationMethod !== CALC_METHOD_REPEATERS) {
      return false
    }
  }
  return true
}
