import { multiPolygon, polygon } from '@turf/helpers'
import { arrayDepth, eqPoint } from '../../utils/geo'
import { arrayLevel } from '../../utils/math'

export const toGeometry = (latLng) => Array.isArray(latLng) ? latLng.map(toGeometry) : [ latLng.lng, latLng.lat ]

export const reverseLngLat = (rings) => {
  const depth = arrayDepth(rings)
  return depth === 1
    ? (rings.length === 2 ? [ rings[1], rings[0] ] : rings)
    : depth > 1 ? rings.map(reverseLngLat) : rings
}

export const checkPolygonIsCorrect = (rings) => {
  if (!rings) {
    return false
  }
  const depth = arrayDepth(rings)
  return depth === 2
    ? (rings.length > 3 || (rings.length === 3 && !eqPoint(rings[0], rings[rings.length - 1])))
    : depth > 2 ? rings.every(checkPolygonIsCorrect) : false
}

export const toTurf = (rings) => {
  const depth = arrayDepth(rings)
  return depth === 2
    ? (eqPoint(rings[0], rings[rings.length - 1]) ? rings : [ ...rings, rings[0] ])
    : depth > 2 ? rings.map(toTurf) : rings
}

export const toPolygon = (rings, levels = 2) =>
  arrayDepth(rings) > levels ? multiPolygon(rings) : polygon(rings)

export const isMultiPolygon = (coordinates) => arrayLevel(coordinates) > 3
