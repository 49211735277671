import { BC_NAME_FIELD, BC_SITE_FIELD } from '../features/bc/constants'
import { DATA_TYPES } from './common'

export const ID_NETWORK = 'network'
export const ID_NETWORK_SITES = `${ID_NETWORK}-sites`
export const ID_NETWORK_SECTORS = `${ID_NETWORK}-sectors`
export const ID_NETWORK_COMPLAINTS = `${ID_NETWORK}-complaints`
export const ID_NETWORK_RBS = `${ID_NETWORK}-rbs`
export const ID_NETWORK_BC = `${ID_NETWORK}-bc`
export const ID_NETWORK_COVERAGE = `${ID_NETWORK}-coverage`

export const SITE_ID_FIELD = 'table.network.site.id'
export const SITE_NAME_FIELD = 'table.network.site.name'
export const SITE_LNG_FIELD = 'table.network.site.geometry.coordinates.lon'
export const SITE_LAT_FIELD = 'table.network.site.geometry.coordinates.lat'
export const SITE_STATUS_FIELD = 'table.network.site.status'
export const SITE_ADDRESS_FIELD = 'table.network.site.address.comment'
export const SITE_LAUNCH_DATE_FIELD = 'table.network.site.launchDate'
export const SITE_KOATOO_ALL_CITIES_FIELD = 'table.network.site.address.koatooAllcities'
export const SITE_KOATOO_MAIN_CITIES_FIELD = 'table.network.site.address.koatooMaincities'
export const SITE_KOATOO_DISTRICTS_FIELD = 'table.network.site.address.koatooDistricts'
export const SITE_POLYGON_FIELD = 'table.network.site.polygon'
export const SITE_COMPOSITE_INDEX_FIELD = 'table.network.site.compositeIndex'
export const SITE_DISTRICT_FIELD = 'table.network.site.address.district'
export const SITE_REGION_FIELD = 'table.network.site.address.region'
export const SITE_CITY_FIELD = 'table.network.site.address.city'
export const SITE_NUMERAL1_FIELD = 'table.network.site.numeral.numeral01'
export const SITE_NUMERAL2_FIELD = 'table.network.site.numeral.numeral02'
export const SITE_NUMERAL3_FIELD = 'table.network.site.numeral.numeral03'
export const SITE_NUMERAL4_FIELD = 'table.network.site.numeral.numeral04'
export const SITE_NUMERAL5_FIELD = 'table.network.site.numeral.numeral05'
export const SITE_SYNC_ATOLL_STATUS_FIELD = 'table.network.site.sync.atoll.status'

export const SECTOR_ID_FIELD = 'table.network.sector.id'
export const SECTOR_NAME_FIELD = 'table.network.sector.name'
export const SECTOR_LNG_FIELD = 'table.network.sector.geometry.coordinates.lon'
export const SECTOR_LAT_FIELD = 'table.network.sector.geometry.coordinates.lat'
export const SECTOR_AZIMUTH_FIELD = 'table.network.sector.geometry.azimuth'
export const SECTOR_HEIGHT_FIELD = 'table.network.sector.geometry.altitude'
export const SECTOR_X_FIELD = 'table.network.sector.geometry.distanceToSector.horizontal'
export const SECTOR_Y_FIELD = 'table.network.sector.geometry.distanceToSector.vertical'
export const SECTOR_TECH_FIELD = 'table.network.sector.device.technology'
export const SECTOR_DEVICE_TYPE_FIELD = 'table.network.sector.device.type'
export const SECTOR_SITE_FIELD = 'table.network.site.id'
export const SECTOR_SITE_NAME_FIELD = 'table.network.sector.site.name'
export const SECTOR_RBS_FIELD = 'table.network.rbs.id'
export const SECTOR_RBS_NAME_FIELD = SECTOR_TECH_FIELD // 'table.network.sector.rbs.name'
export const SECTOR_STATUS_FIELD = 'table.network.sector.status'
export const SECTOR_LAUNCH_DATE_FIELD = 'table.network.sector.launchDate'
export const SECTOR_COMPOSITE_INDEX_FIELD = 'table.network.sector.compositeIndex'
export const SECTOR_BANDWIDTH_FIELD = 'table.network.sector.device.bandwidth'
export const SECTOR_MECHANICAL_TILT_FIELD = 'table.network.sector.device.tilt.mechanical'
export const SECTOR_ELECTRICAL_TILT_FIELD = 'table.network.sector.device.tilt.electrical'
export const SECTOR_CELL_CARRIERS_FIELD = 'table.network.sector.device.trxCount'
export const SECTOR_NUMERAL1_FIELD = 'table.network.sector.numeral.numeral01'
export const SECTOR_NUMERAL2_FIELD = 'table.network.sector.numeral.numeral02'
export const SECTOR_NUMERAL3_FIELD = 'table.network.sector.numeral.numeral03'
export const SECTOR_NUMERAL4_FIELD = 'table.network.sector.numeral.numeral04'
export const SECTOR_NUMERAL5_FIELD = 'table.network.sector.numeral.numeral05'
export const SECTOR_SUBSCRIBERS_WITH_2G = 'table.network.sector.stat.terminalCount.by2g'
export const SECTOR_SUBSCRIBERS_WITH_3G = 'table.network.sector.stat.terminalCount.by3g'
export const SECTOR_HSDPA_USERS = 'table.network.sector.stat.terminalCount.byHSDPA'
export const SECTOR_SUBSCRIBERS_WITH_4G_WITHOUT_USIM = 'table.network.sector.stat.terminalCount.by4gWithoutUsim'
export const SECTOR_SUBSCRIBERS_WITH_4G = 'table.network.sector.stat.terminalCount.by4g'
export const SECTOR_SUBSCRIBERS_WITH_VOLTE = 'table.network.sector.stat.terminalCount.volte'
export const SECTOR_LTE_ACTIVE_DATA_USERS = 'table.network.sector.stat.terminalCount.by4gData'
export const SECTOR_USER_THROUGHPUT = 'table.network.sector.stat.traffic.data.bandwidth.3g4g'
export const SECTOR_PRB_UTILIZATION = 'table.network.sector.stat.traffic.data.quality.4g'
export const SECTOR_TIMING_ADVANCE = 'table.network.sector.timingAdvance'
export const SECTOR_SYNC_ATOLL_STATUS_FIELD = 'table.network.sector.sync.atoll.status'

export const RBS_ID_FIELD = 'table.network.rbs.id'
export const RBS_SITE_FIELD = 'table.network.site.id'
export const RBS_SITE_NAME_FIELD = 'table.network.rbs.site.name'
export const RBS_STATUS_FIELD = 'table.network.rbs.status'
export const RBS_COMPOSITE_INDEX_FIELD = 'table.network.rbs.compositeIndex'
export const RBS_NUMERAL1_FIELD = 'table.network.rbs.numeral.numeral01'
export const RBS_NUMERAL2_FIELD = 'table.network.rbs.numeral.numeral02'
export const RBS_NUMERAL3_FIELD = 'table.network.rbs.numeral.numeral03'
export const RBS_NUMERAL4_FIELD = 'table.network.rbs.numeral.numeral04'
export const RBS_NUMERAL5_FIELD = 'table.network.rbs.numeral.numeral05'
export const RBS_TECHNOLOGY_FIELD = 'table.network.rbs.device.technology'
export const RBS_NAME_FIELD = RBS_TECHNOLOGY_FIELD // 'table.network.rbs.name'

export const COMPLAINT_ID_FIELD = 'table.complaint.id_external'
export const COMPLAINT_NAME_FIELD = 'table.complaint.id_external'
export const COMPLAINT_LNG_FIELD = 'table.complaint.geometry.coordinates.lon'
export const COMPLAINT_LAT_FIELD = 'table.complaint.geometry.coordinates.lat'
export const COMPLAINT_SITE_FIELD = 'table.complaint.site.id'
export const COMPLAINT_SECTOR_FIELD = 'table.complaint.sector.id'
export const COMPLAINT_SITE_NAME_FIELD = 'table.network.site.name'
export const COMPLAINT_SECTOR_NAME_FIELD = 'table.network.sector.name'
export const COMPLAINT_TYPE_P_FIELD = 'table.complaint.type_p'
export const COMPLAINT_TYPE_S_FIELD = 'table.complaint.type_s'
export const COMPLAINT_DATE_FIELD = 'table.complaint.date.created'
export const COMPLAINT_ADDRESS_FIELD = 'table.complaint.subscriber.address.full'
export const COMPLAINT_WEIGHT_FIELD = 'table.complaint.weight'
export const COMPLAINT_ACCIDENT_ON_BS_FIELD = 'table.complaint.rbs.accidentOnBs'

export const SITE_DATE_FIELD = 'table.network.site.date'
export const RBS_DATE_FIELD = 'table.network.rbs.date'
export const SECTOR_DATE_FIELD = 'table.network.sector.date'

export const EXCLUDED_FIELDS = [
  SITE_ID_FIELD, RBS_ID_FIELD, SECTOR_ID_FIELD,
  RBS_SITE_FIELD, SECTOR_RBS_FIELD, SECTOR_SITE_FIELD, COMPLAINT_SITE_FIELD, COMPLAINT_SECTOR_FIELD,
]

export const NAME_PREFIX = 'SNC_'

export const STATUS_ACTIVE = 'ACTIVE'
export const STATUS_DRAFT = 'DRAFT'
export const STATUS_CANDIDATE = 'CANDIDATE'
export const STATUS_REMOVED = 'REMOVED'

export const CAN_BE_REMOVED = [ STATUS_DRAFT, STATUS_CANDIDATE ]

export const DIVIDER = '-->>--'

export const SECTORS = [
  'NONE',
  'GSM900',
  'GSM1800',
  'LTE900',
  'LTE1800',
  'LTE2100',
  'LTE2300',
  'LTE2600',
  'UMTS2100',
]

export const SECTOR_TYPES = {
  NONE: 0,
  GSM900: 2,
  GSM1800: 6,
  LTE900: 3,
  LTE1800: 4,
  LTE2100: 7,
  LTE2300: 8,
  LTE2600: 5,
  UMTS2100: 1,
}

export const SECTOR_COLORS = {
  NONE: '#000000',
  GSM900: '#4040ff',
  GSM1800: '#00f000',
  LTE900: '#00f000',
  LTE1800: '#4040ff',
  LTE2100: '#4080ff',
  LTE2300: '#40AAff',
  LTE2600: '#40ffff',
  UMTS2100: '#00f000',
}

export const SECTOR_BASE = {
  NONE: null,
  GSM900: 'GSM',
  GSM1800: 'GSM',
  UMTS2100: 'UMTS',
  LTE900: 'LTE',
  LTE1800: 'LTE',
  LTE2100: 'LTE',
  LTE2300: 'LTE',
  LTE2600: 'LTE',
}

export const extractTypeUnique = (item) => {
  const [ , result ] = /(\D*)(\d*)/gm.exec(item)
  return result
}

export const SECTOR_TYPES_LIST = Object.keys(SECTOR_TYPES).slice(1)

export const SECTOR_TYPES_UNIQUE = [ ...new Set(SECTOR_TYPES_LIST.map(extractTypeUnique)) ]

export const DEFAULT_TECH = 'GSM900'

export const ID_NETWORK_TO_DATA_TYPE = {
  [ID_NETWORK_RBS]: DATA_TYPES.BASE_STATIONS,
  [ID_NETWORK_SITES]: DATA_TYPES.SITES,
  [ID_NETWORK_SECTORS]: DATA_TYPES.SECTORS,
  [ID_NETWORK_COMPLAINTS]: DATA_TYPES.COMPLAINTS,
  [ID_NETWORK_BC]: DATA_TYPES.BUSINESS_CASES,
}

export const ENUM_TECHNOLOGY = {
  GSM900: 'GSM900',
  GSM1800: 'GSM1800',
  UMTS2100: 'UMTS2100',
  LTE900: 'LTE900',
  LTE1800: 'LTE1800',
  LTE2100: 'LTE2100',
  LTE2300: 'LTE2300',
  LTE2600: 'LTE2600',
  NONE: 'NONE',
}

export const PROJECT_FIELD_KPI = {
  sites: [
    SITE_ID_FIELD, SITE_STATUS_FIELD,
    SITE_NUMERAL1_FIELD, SITE_NUMERAL2_FIELD, SITE_NUMERAL3_FIELD, SITE_NUMERAL4_FIELD, SITE_NUMERAL5_FIELD,
  ],
  rbs: [
    RBS_ID_FIELD, RBS_SITE_FIELD, RBS_STATUS_FIELD,
    RBS_NUMERAL1_FIELD, RBS_NUMERAL2_FIELD, RBS_NUMERAL3_FIELD, RBS_NUMERAL4_FIELD, RBS_NUMERAL5_FIELD,
  ],
  sectors: [
    SECTOR_ID_FIELD, SECTOR_RBS_FIELD, SECTOR_SITE_FIELD, SECTOR_STATUS_FIELD,
    SECTOR_NUMERAL1_FIELD, SECTOR_NUMERAL2_FIELD, SECTOR_NUMERAL3_FIELD, SECTOR_NUMERAL4_FIELD, SECTOR_NUMERAL5_FIELD,
  ],
}

export const SYNC_ATOLL_STATUS_SYNCED = 'SYNCED'
export const SYNC_ATOLL_STATUS_NEVER = 'NEVER'

export const LNG_ATTR = [
  SITE_LNG_FIELD,
  SECTOR_LNG_FIELD,
  COMPLAINT_LNG_FIELD,
]

export const LAT_ATTR = [
  SITE_LAT_FIELD,
  SECTOR_LAT_FIELD,
  COMPLAINT_LAT_FIELD,
]

export const COMPOSITE_INDICES = [
  SITE_COMPOSITE_INDEX_FIELD,
  SECTOR_COMPOSITE_INDEX_FIELD,
  RBS_COMPOSITE_INDEX_FIELD,
]

// перечень атрибутов заполняемых в верхнем регистре
export const UPPER_CASE_ATTR = [
  SITE_NAME_FIELD,
  SECTOR_NAME_FIELD,
  RBS_NAME_FIELD,
  BC_NAME_FIELD,
  BC_SITE_FIELD,
]

// Константа визначає, скільки приблизно елементів за раз відправляється на бекенд (сайти + всі іх RBS + всі їх сектори)
export const NETWORK_ELEMENT_COUNT = 256
