import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { MapContainer } from 'react-leaflet'
import { useHistory } from 'react-router-dom'
import { useBoolean } from '@fluentui/react-hooks'
import {
  saveEditedZone, editOrCreateFilteringZone, editOrCreateComputationZone, selectEditZone, createFocusZone,
  showBuildPainZones,
  setRulerVisible,
  selectRulerVisible,
  copyCoordinatesToClipboard,
  selectGlobalDrawMode,
} from '../../../features/geo/geoSlice'
import {
  selectReloadTC, updateSectorTargetCoverage, menuItemClick, createNewSite,
  selectSitesFull, selectSiteFields, selectCreateSiteMode,
  selectSectorsFull, selectSectorFields,
} from '../../../features/network/networkSlice'
import { setPanel, setTab } from '../../../features/panel/panelSlice'
import {
  selectIsDefaultProject, selectIsMyProject, selectIsSharedProject,
} from '../../../features/projects/projectsSlice'
import { selectUserAccess } from '../../../features/login/loginSlice'
import { importVectorMap } from '../../../features/vector/vectorSlice'
import { importRasterMap } from '../../../features/raster/rasterSlice'
import { selectOfflineMode } from '../../../features/loading/loadingSlice'
import { findIndex } from '../../../features/network/indexing'
import { useConfirm } from '../../common/Confirm'
import DialogWithApply from '../../common/DialogWithApply'
import ContextMenu from '../../common/ContextMenu'
import { BUSINESS_CASES, COMPLAINTS, EDIT_POLYGONS, IMPORT_MAPS } from '../../../constants/access'
import {
  addSiteToNeighbors, addSectorToNeighbors, selectIsEditNeighborsOpened, createBCForSite,
  isAllowCreateBC,
} from '../../../features/bc/bcSlice'
import { selectCoordinatesFormat } from '../../../features/settings/settingsSlice'
import { serializeGeometry } from '../../../utils/geo'
import {
  SECTOR_STATUS_FIELD, SITE_NAME_FIELD, SITE_STATUS_FIELD, STATUS_ACTIVE, STATUS_DRAFT,
} from '../../../constants/network'
import { PlusIcon } from '../../common/icons/names'
import '../plugins/leaflet-markers-canvas'
import '../plugins/leaflet-control-button'
import '../plugins/leaflet-pip'
import '../plugins/snc-circle'
import { getElementList } from '../list'
import PolygonProperties from './PolygonProperties'
import GeoContent from './GeoContent'
import {
  mapContextMenu, MENU_KEY_CALCULATE_PAIN_ZONES, MENU_KEY_CREATE_ZONE, MENU_KEY_IMPORT_MAP, menuItemByKey,
} from './constants'

import './Map.css'
import './MarkerClaster.css'

// Завантаження збереженого вьюпорту
const position = JSON.parse(localStorage.getItem('mapPosition') || '[50.45,30.52]')
const zoom = localStorage.getItem('mapZoom') || 13

let contextMenuPoint = null

const fileNameAndExt = (fileName) => {
  const arr = fileName.split('.')
  let ext = ''
  if (arr.length > 1) {
    ext = arr.pop()
  }
  return [ arr.join('.'), ext ]
}

const mifAndMid = (ext1, ext2) => (ext1.toLowerCase() === 'mif' && ext2.toLowerCase() === 'mid') ||
  (ext1.toLowerCase() === 'mid' && ext2.toLowerCase() === 'mif')

const kmlOrKmz = (ext) => ext.toLowerCase() === 'kml' || ext.toLowerCase() === 'kmz'

const Map = ({ shown = true }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { renderConfirm, msg } = useConfirm()

  const geoContentRef = useRef()

  const [ hideDialog, { setFalse: openDialog, setTrue: closeDialog } ] = useBoolean(true)
  const [ polygon, setPolygon ] = useState({})
  const [ reloadTargetCoverages, setReloadTC ] = useState(1)
  const [ menuTarget, setMenuTarget ] = useState(null)
  const [ contextMenuVisible, { setFalse: hideContextMenu, setTrue: showContextMenu } ] = useBoolean(false)

  const reloadTC = useSelector(selectReloadTC)
  const isMyProject = useSelector(selectIsMyProject)
  const isDefaultProject = useSelector(selectIsDefaultProject)
  const isSharedProject = useSelector(selectIsSharedProject)
  const editZone = useSelector(selectEditZone)
  const userAccess = useSelector(selectUserAccess, shallowEqual)
  const offlineMode = useSelector(selectOfflineMode)
  const rulerVisible = useSelector(selectRulerVisible)

  const isEditNeighborsOpened = useSelector(selectIsEditNeighborsOpened)
  const coordinatesFormat = useSelector(selectCoordinatesFormat, shallowEqual)
  const siteFull = useSelector(selectSitesFull)
  const siteFields = useSelector(selectSiteFields)

  const sectorFull = useSelector(selectSectorsFull)
  const sectorFields = useSelector(selectSectorFields)

  const globalDrawMode = useSelector(selectGlobalDrawMode)
  const inCreateSiteMode = useSelector(selectCreateSiteMode)

  const siteNameFldIdx = useMemo(() => findIndex(siteFields, SITE_NAME_FIELD), [ siteFields ])
  const siteStatusFldIdx = useMemo(() => findIndex(siteFields, SITE_STATUS_FIELD), [ siteFields ])
  const sectorStatusFldIdx = useMemo(() => findIndex(sectorFields, SECTOR_STATUS_FIELD), [ sectorFields ])
  const createBCHidden = useMemo(() => {
    return !userAccess[BUSINESS_CASES] || !isMyProject
  }, [ userAccess, isMyProject ])

  useEffect(() => {
    if (globalDrawMode && contextMenuVisible) {
      hideContextMenu()
    }
  }, [ globalDrawMode, contextMenuVisible, hideContextMenu ])

  const showContextMenuAt = useCallback((mouseEvent) => {
    if (!globalDrawMode && !inCreateSiteMode) {
      setMenuTarget(mouseEvent)
      showContextMenu()
    }
  }, [ setMenuTarget, showContextMenu, globalDrawMode, inCreateSiteMode ])

  const updateTCVersion = useCallback(() => {
    setReloadTC((version) => version + 1)
  }, [ setReloadTC ])

  useEffect(() => {
    updateTCVersion()
  }, [ reloadTC, updateTCVersion ])

  const onEditPolygon = useCallback((data) => {
    setPolygon(data)
    openDialog()
  }, [ setPolygon, openDialog ])

  const onSaveData = useCallback(async (data) => {
    setPolygon(data)
    const { id, geometry, properties, uuid, type } = data
    switch (type) {
      case 'targetCoverage': {
        const { site: siteId, id: sectorId } = data
        if (siteId && sectorId) {
          await dispatch(updateSectorTargetCoverage(siteId, sectorId, geometry, properties))
          updateTCVersion()
        }
        break
      }
      default:
        dispatch(saveEditedZone({
          id,
          zone: geometry,
          properties,
          uuid,
        }))
    }
  }, [ setPolygon, dispatch, updateTCVersion ])

  const handleMenuItemClick = useCallback(async (event, item) => {
    switch (item.key) {
      case 'create-site-map': {
        dispatch(menuItemClick(null, 'create-site'))
        break
      }
      case 'create-site-manual': {
        const siteId = await dispatch(createNewSite(contextMenuPoint))
        if (geoContentRef?.current && geoContentRef.current.setActiveSite) {
          geoContentRef.current.setActiveSite(siteId)
          dispatch(setPanel('edit-site'))
        }
        break
      }
      case 'network-sites': {
        await dispatch(setPanel('network'))
        dispatch(setTab('sites'))
        break
      }
      case 'network-sectors': {
        await dispatch(setPanel('network'))
        dispatch(setTab('sectors'))
        break
      }
      case 'network-rbs': {
        await dispatch(setPanel('network'))
        dispatch(setTab('rbs'))
        break
      }
      case 'network-coverage': {
        await dispatch(setPanel('network'))
        dispatch(setTab('coverage'))
        break
      }
      case 'network-complaints': {
        await dispatch(setPanel('network'))
        dispatch(setTab('complaints'))
        break
      }
      case 'network-business-cases': {
        await dispatch(setPanel('network'))
        dispatch(setTab('business-cases'))
        break
      }
      case 'create-zone-filtering': {
        dispatch(editOrCreateFilteringZone)
        break
      }
      case 'create-zone-computation': {
        dispatch(editOrCreateComputationZone)
        break
      }
      case 'create-zone-focus': {
        dispatch(createFocusZone)
        break
      }
      case 'calculate-pain-zones': {
        dispatch(showBuildPainZones())
        break
      }
      case 'import-map-vector': {
        document.getElementById('vector-file-input').click()
        break
      }
      case 'import-map-raster': {
        document.getElementById('raster-file-input').click()
        break
      }
      case 'customization-map-style': {
        await dispatch(setPanel('customization'))
        dispatch(setTab('map-style'))
        break
      }
      case 'customization-colors': {
        await dispatch(setPanel('customization'))
        dispatch(setTab('colors'))
        break
      }
      case 'search': {
        dispatch(setPanel('search-map'))
        break
      }
      case 'show-ruler': {
        dispatch(setRulerVisible(true))
        break
      }
      case 'hide-ruler': {
        dispatch(setRulerVisible(false))
        break
      }
      case 'add-all-to-neighbors': {
        dispatch(addSiteToNeighbors(item.target))
        break
      }
      case 'add-neighbor': {
        dispatch(addSectorToNeighbors(item.target))
        break
      }
      case 'copy_coordinates': {
        const coordinates = menuTarget?.latlng &&
          serializeGeometry([ menuTarget.latlng.lng, menuTarget?.latlng.lat ], coordinatesFormat)
        dispatch(copyCoordinatesToClipboard(coordinates))
        break
      }
      case 'create_bc': {
        dispatch(createBCForSite(item.draftSite, history))
        break
      }
      default: {
        console.warn('Map context menu:', item.key)
        break
      }
    }
  }, [ dispatch, menuTarget, coordinatesFormat, history ])

  const handleMenuOpen = useCallback((event) => {
    contextMenuPoint = event.target.latlng
  }, [])

  useEffect(() => {
    const disallowEditZones = (!isMyProject && !isDefaultProject) || editZone || !userAccess[EDIT_POLYGONS]
    const disallowComplaints = !userAccess[COMPLAINTS]
    menuItemByKey(MENU_KEY_CREATE_ZONE).disabled = disallowEditZones
    menuItemByKey(MENU_KEY_CALCULATE_PAIN_ZONES).disabled = disallowEditZones || disallowComplaints
    menuItemByKey(MENU_KEY_IMPORT_MAP).disabled = !isMyProject || !userAccess[IMPORT_MAPS]
  }, [ isMyProject, isDefaultProject, editZone, userAccess ])

  const mapInputChange = useCallback((inputId, importMethod) => {
    const input = document.getElementById(inputId)
    if (input.files.length === 2) {
      const [ name1, ext1 ] = fileNameAndExt(input.files[0].name)
      const [ name2, ext2 ] = fileNameAndExt(input.files[1].name)
      if (name1 === name2 && mifAndMid(ext1, ext2)) {
        const formData = new FormData()
        formData.append(`${name1}.${ext1}`, input.files[0])
        formData.append(`${name2}.${ext2}`, input.files[1])
        dispatch(importMethod({ formData, name: name1 }))
        input.value = null
        return
      }
    } else if (input.files.length === 1) {
      const [ name, ext ] = fileNameAndExt(input.files[0].name)
      if (kmlOrKmz(ext)) {
        const formData = new FormData()
        formData.append(`${name}.${ext}`, input.files[0])
        dispatch(importMethod({ formData, name }))
        input.value = null
        return
      }
    }
    input.value = null
    msg({
      messages: [
        'You can import only one Vector Map at a time.',
        'Please select one KML or KMZ file, or two MIF and MID files with same name.',
      ],
    })
  }, [ msg, dispatch ])

  const vectorFileChange = useCallback(() => {
    mapInputChange('vector-file-input', importVectorMap)
  }, [ mapInputChange ])

  const rasterFileChange = useCallback(() => {
    mapInputChange('raster-file-input', importRasterMap)
  }, [ mapInputChange ])

  const menuItems = useMemo(() => {
    const list = menuTarget?.layerX && getElementList(window.map, { x: menuTarget.layerX, y: menuTarget.layerY })
    if (isEditNeighborsOpened && menuTarget) {
      const activeList = list
        ?.filter((item) => item.options?.active && [ 'sector', 'site' ].includes(item.options.type))
        .map((item) => item.options)
      if (activeList?.length > 0) {
        if (activeList.length === 1 && activeList[0].type === 'sector') {
          return [
            {
              key: 'add-neighbor',
              text: 'Add sector to BC',
              icon: PlusIcon,
              target: activeList[0],
            },
          ]
        } else {
          return [
            {
              key: 'add-all-to-neighbors',
              text: 'Add all sectors to BC',
              icon: PlusIcon,
              target: activeList,
            },
          ]
        }
      }
      return []
    }
    const coordinates = menuTarget?.latlng &&
      serializeGeometry([ menuTarget.latlng.lng, menuTarget?.latlng.lat ], coordinatesFormat)
    let activeSite
    if (!createBCHidden) {
      const activeSiteId = window.map?._activeSite
      if (activeSiteId) {
        // Create BC for the selected Site
        const siteInd = siteFull.findIndexById(activeSiteId)
        if (siteInd >= 0) {
          const site = siteFull.getList()[siteInd]
          const siteStatus = site?.[siteStatusFldIdx]
          const siteName = site?.[siteNameFldIdx]
          const isAllow = isAllowCreateBC(activeSiteId, siteStatus, sectorFull, sectorStatusFldIdx)

          if (isAllow) {
            activeSite = { id: activeSiteId, name: siteName, draft: siteStatus === STATUS_DRAFT }
          }
        }
      } else {
        // Create BC for the point on map (find an appropriate site)
        const targetSites = list
          ?.filter((item) => item.options.type === 'site').map((item) => item.options)
        if (targetSites?.length === 1) {
          const siteId = targetSites[0].id
          const siteName = targetSites[0].name
          const siteStatus = targetSites[0].active ? STATUS_ACTIVE : STATUS_DRAFT
          const isAllow = isAllowCreateBC(siteId, siteStatus, sectorFull, sectorStatusFldIdx)
          if (isAllow) {
            activeSite = { id: siteId, name: siteName, draft: !targetSites[0].active }
          }
        }
      }
    }
    return mapContextMenu(userAccess, offlineMode, isSharedProject, rulerVisible, coordinates, activeSite)
  }, [
    userAccess, offlineMode, isSharedProject, rulerVisible,
    isEditNeighborsOpened, menuTarget, coordinatesFormat,
    createBCHidden, siteFull, siteNameFldIdx,
    siteStatusFldIdx, sectorFull, sectorStatusFldIdx,
  ])

  return (
    <>
      <input
        type="file"
        id="vector-file-input"
        accept=".mif,.mid,.kml,.kmz"
        multiple
        style={{ display: 'none' }}
        onChange={vectorFileChange}
      />
      <input
        type="file"
        id="raster-file-input"
        accept=".mif,.mid,.kml,.kmz"
        multiple
        style={{ display: 'none' }}
        onChange={rasterFileChange}
      />
      <MapContainer
        id="map-container"
        center={position}
        zoom={zoom}
        className="full-height"
        preferCanvas
        zoomDelta={0.5}
        zoomSnap={0.5}
        minZoom={1}
        zoomControl={false}
        attributionControl={false}
      >
        <GeoContent
          ref={geoContentRef}
          onEditPolygon={onEditPolygon}
          shown={shown}
          reloadTargetCoverages={reloadTargetCoverages}
          onNeedUpdateTCVersion={updateTCVersion}
          onShowContextMenu={showContextMenuAt}
        />
      </MapContainer>
      <DialogWithApply
        title="Polygon"
        content={PolygonProperties}
        data={polygon}
        onSaveData={onSaveData}
        onClose={closeDialog}
        hidden={hideDialog}
      />
      <ContextMenu
        items={menuItems}
        onOpen={handleMenuOpen}
        onClose={hideContextMenu}
        onItemClick={handleMenuItemClick}
        visible={contextMenuVisible}
        target={menuTarget}
      />
      {renderConfirm()}
    </>
  )
}

export default Map
