import merge from 'deepmerge'
import { createSlice } from '@reduxjs/toolkit'
import { postBundle } from '../../api/network'
import { activeProjectId } from '../projects/projectsSlice'
import { clearUndoRecords, doUndo, selectUndoPosition } from '../undo/undoSlice'
import { errorEventLog, EVENT_TYPE, saveEventLog, wrapByEventLog } from '../eventLog/eventLogSlice'
import { NETWORK_ELEMENT_COUNT } from '../../constants/network'
import { calcChanges } from '../../utils/bundle'
import { STAGE_KEYS } from './keys'

const initialState = {
  loading: false,
  stages: null,
  offlineMode: false,
  changes: {},
  savingChanges: null,
}

const changesCount = (state) => {
  let result = 0
  const { changes } = state
  if (changes) {
    result += calcChanges(changes)
  }
  return result
}

let cacheChangesCount = 0

const beforeUnload = (event) => {
  const result = cacheChangesCount
    ? 'You have unsaved changes in the project. Do you really want to leave this page? (All changes will be lost)'
    : null
  if (result) {
    event.preventDefault()
    event.returnValue = result
    return result
  }
}

const mergeChanges = (base, part) => {
  if (base && part && part.attributes && part.origins) {
    base.origins = merge(part.origins, base.origins ?? {})
    base.attributes = merge(base.attributes ?? {}, part.attributes)
    const keys = Object.keys(base.attributes)
    for (const key of keys) {
      const value = base.attributes[key]
      const origin = base.origins?.[key]
      if (value === origin) {
        delete base.attributes[key]
      }
    }
  }
  const keys = Object.keys(part)
  for (const key of keys) {
    if (key !== 'attributes' && key !== 'origins') {
      if (!base[key]) {
        base[key] = part[key]
      } else {
        mergeChanges(base[key], part[key])
      }
    }
  }
}

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    addStage: (state, action) => {
      const { name, stage } = action.payload ?? {}
      if (!state.stages) {
        state.stages = []
      }
      state.stages.push({
        id: `${name}-${stage}`,
        name: name,
        current: stage,
      })
    },
    stageLoaded: (state, action) => {
      if (!state.stages) {
        return
      }
      const stage = state.stages.find(({ id }) => id === `${action.payload}-${STAGE_KEYS.LOADING}`)
      if (stage) {
        stage.current = STAGE_KEYS.LOADED
      }
    },
    stagePayload: (state, action) => {
      if (!state.stages) {
        return
      }
      const { stageName, payloadValue } = action.payload
      const stage = state.stages.find(({ id }) => id === `${stageName}-${STAGE_KEYS.FILTERING}`)
      if (stage) {
        stage.payload = payloadValue
      }
    },
    stageIndexed: (state, action) => {
      if (!state.stages) {
        return
      }
      const stage = state.stages.find(({ id }) => id === `${action.payload}-${STAGE_KEYS.INDEXING}`)
      if (stage) {
        stage.current = STAGE_KEYS.INDEXED
      }
    },
    stageFiltered: (state, action) => {
      if (!state.stages) {
        return
      }
      const stage = state.stages.find(({ id }) => id === `${action.payload}-${STAGE_KEYS.FILTERING}`)
      if (stage) {
        stage.current = STAGE_KEYS.FILTERED
      }
    },
    stageCompleted: (state, action) => {
      if (!state.stages) {
        return
      }
      const stage = state.stages.find(({ id }) => id === `${action.payload}-${STAGE_KEYS.LOADING}`)
      if (stage) {
        stage.previous = stage.current
        stage.current = STAGE_KEYS.COMPLETE
      }
    },
    stageAborted: (state, action) => {
      if (!state.stages) {
        return
      }
      const stage = state.stages.find(({ id }) => id === `${action.payload}-${STAGE_KEYS.LOADING}`)
      if (stage) {
        stage.previous = stage.current
        stage.current = STAGE_KEYS.ABORTED
      }
    },
    clearStages: (state) => {
      state.stages = null
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setOfflineMode: (state, action) => {
      state.offlineMode = action.payload
      if (state.offlineMode) {
        window.addEventListener('beforeunload', beforeUnload)
      } else {
        window.removeEventListener('beforeunload', beforeUnload)
      }
    },
    setChanges: (state, action) => {
      state.changes = action.payload
    },
    addChanges: (state, action) => {
      mergeChanges(state.changes, action.payload.sites)
      cacheChangesCount = changesCount(state)
    },
    clearChanges: (state, action) => {
      state.changes = {}
      cacheChangesCount = changesCount(state)
    },
    setSavingChanges: (state, action) => {
      state.savingChanges = action.payload
    },
  },
})

export const { setSavingChanges } = loadingSlice.actions
const { clearChanges, setChanges } = loadingSlice.actions

export const {
  setLoading,
  stageLoaded,
  addStage,
  stageCompleted,
  stageAborted,
  stageIndexed,
  stageFiltered,
  clearStages,
  stagePayload,
  addChanges,
  setOfflineMode,
} = loadingSlice.actions

export const selectLoading = (state) => state.loading.loading
export const selectStages = (state) => state.loading.stages
export const selectOfflineMode = (state) => state.loading.offlineMode
export const selectChangesCount = (state) => changesCount(state.loading)
export const selectSavingChanges = (state) => state.loading.savingChanges

export const processUnsavedChanges = () => (dispatch, getState) => {
  const title = 'Saving changes'
  const state = getState()
  const total = selectChangesCount(state)
  const projectId = activeProjectId(state)
  let current = 0
  let notSaved = {}

  return dispatch(wrapByEventLog({
    type: EVENT_TYPE.autoSaveMode,
    details: `Saving; Changes = ${total}`,
    action: async () => {
      await dispatch(clearUndoRecords())
      await dispatch(setSavingChanges({ total, current, title }))
      try {
        const sites = Object.keys(state.loading.changes)
        while (sites.length) {
          let portion = 0
          let elementCount = 0
          while (portion < sites.length && elementCount < NETWORK_ELEMENT_COUNT) {
            portion++
            elementCount += calcChanges(state.loading.changes[sites[portion - 1]])
          }
          const keys = sites.splice(0, portion)
          const packet = {}
          for (const key of keys) {
            packet[key] = state.loading.changes[key]
          }
          try {
            await postBundle(projectId, { sites: packet })
          } catch (err) {
            console.error(err)
            notSaved = merge(notSaved, packet)
            await dispatch(errorEventLog(EVENT_TYPE.autoSaveMode, err, ` [Saving; Changes saved so far: ${current} / ${total}]`))
          }
          current += calcChanges(packet)
          await dispatch(setSavingChanges({ total, current, title }))
        }
        if (Object.keys(notSaved).length > 0) {
          dispatch(setChanges(notSaved))
          return false
        } else {
          dispatch(clearChanges())
        }
      } finally {
        setTimeout(() => dispatch(setSavingChanges(null)), 500)
      }
      return true
    },
    extractor: (result) => result
      ? 'All changes saved'
      : `Some changes not saved [Changes = ${selectChangesCount(getState())}]`,
  }))
}

export const rollbackUnsavedChanges = () => (dispatch, getState) => {
  const title = 'Rollback changes'
  const state = getState()
  const total = selectUndoPosition(state)
  let current = 0

  return dispatch(wrapByEventLog({
    type: EVENT_TYPE.autoSaveMode,
    details: `Rollback; Changes = ${total}`,
    action: async () => {
      await dispatch(setSavingChanges({ total, current, title }))
      try {
        for (let i = 0; i < total; i++) {
          dispatch(setSavingChanges(null))
          await dispatch(doUndo())
          current++
          await dispatch(setSavingChanges({ total, current, title }))
        }
        await dispatch(clearChanges())
        await dispatch(clearUndoRecords())
      } finally {
        setTimeout(() => dispatch(setSavingChanges(null)), 500)
      }
      return true
    },
  }))
}

export const clearChangesAndUndo = () => async (dispatch, getState) => {
  const state = getState()
  const total = selectChangesCount(state)
  await dispatch(clearChanges())
  await dispatch(clearUndoRecords())
  if (total) {
    await dispatch(saveEventLog(EVENT_TYPE.autoSaveMode, ` [Clear; Changes = ${total}]`))
  }
  return true
}

export default loadingSlice.reducer
