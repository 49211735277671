import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useBoolean } from '@fluentui/react-hooks'
import {
  selectEditActive, selectEditBundle, selectEditPath, cleanUpBundle, setBundlePath,
  selectSiteFldIdx, selectSectorFldIdx, saveBundle, ACTION_UPDATE, selectBaseStationFldIdx,
  selectSitesFull, selectSectorsFull, selectInUpdate, extendSiteBundleToMoveSectors,
} from '../../features/network/networkSlice'
import { selectUserAccess } from '../../features/login/loginSlice'
import { selectIsDefaultProject, selectIsMyProject } from '../../features/projects/projectsSlice'
import { selectRefill, selectResolve } from '../../features/panel/panelSlice'
import { checkConvertValue } from '../../features/network/utils'
import {
  CAN_BE_REMOVED, RBS_NAME_FIELD, SECTOR_NAME_FIELD, SITE_NAME_FIELD, STATUS_ACTIVE, STATUS_REMOVED,
} from '../../constants/network'
import { useConfirm } from '../common/Confirm'
import { SecondaryButton, PrimaryButton } from '../common/Button'
import { EDIT_ELEMENTS } from '../../constants/access'
import { store } from '../../features/store'
import NetworkItemProperties from './NetworkItemProperties'
import NetworkItemBundleTree from './NetworkItemBundleTree'
import NetworkItemBundleBreadcrumbs from './NetworkItemBundleBreadcrumbs'

import './NetworkItemEditor.css'

const NetworkItemEditor = ({ onClose, confirmClose }) => {
  const detailsCaptionDiv = document.getElementById('panel-details-caption')
  const detailsContentDiv = document.getElementById('panel-details-content')

  const dispatch = useDispatch()
  const { renderConfirm, ask } = useConfirm()

  const userAccess = useSelector(selectUserAccess, shallowEqual)
  const isMyProject = useSelector(selectIsMyProject)
  const isDefaultProject = useSelector(selectIsDefaultProject)
  const active = useSelector(selectEditActive)
  const path = useSelector(selectEditPath)
  const editBundle = useSelector(selectEditBundle(true))
  const [ , siteNameFldIdx, siteLngFldIdx, siteLatFldIdx, siteStatusFldIdx ] =
    useSelector(selectSiteFldIdx, shallowEqual)
  const [ , baseStationNameFldIdx,, baseStatusStatusFldIdx, baseStationSiteNameFldIdx ] =
    useSelector(selectBaseStationFldIdx, shallowEqual)
  const [ , sectorNameFldIdx, sectorLngFldIdx, sectorLatFldIdx,,
    sectorDXFldIdx, sectorDYFldIdx,, sectorStatusFldIdx,,
    sectorBaseStationNameFldIdx,, sectorSiteNameFldIdx ] =
    useSelector(selectSectorFldIdx, shallowEqual)
  const refill = useSelector(selectRefill)
  const resolve = useSelector(selectResolve)

  const sitesFull = useSelector(selectSitesFull)
  const sectorsFull = useSelector(selectSectorsFull)

  const inUpdate = useSelector(selectInUpdate)

  const [ bundle, setBundle ] = useState(editBundle)
  const [ updates, setUpdates ] = useState(0)
  const [ isModified, { setTrue: setModified, setFalse: setNotModified } ] = useBoolean(false)
  const [ isSaving, { setTrue: setSaving, setFalse: setNotSaving } ] = useBoolean(false)

  useEffect(() => {
    if (isSaving) {
      return
    }
    if (!bundle || !editBundle || inUpdate || refill || bundle?.id !== editBundle?.id ||
        (editBundle?.type === 'complaint' && bundle?.name !== editBundle?.name)) {
      if (JSON.stringify(bundle) !== JSON.stringify(editBundle)) {
        setBundle(editBundle)
      }
    } else if (bundle && bundle?.id === editBundle?.id) {
      const targetBundle = path?.reduce((acc, index) => acc?.children?.[index], bundle)
      const sourceBundle = path?.reduce((acc, index) => acc?.children?.[index], editBundle)

      const tagrgetElement = [ ...targetBundle.element ]
      const targetModified = targetBundle.modified ? [ ...targetBundle.modified ] : null

      let coordinateFieldIdxs = []
      if (targetBundle?.type === 'site') {
        coordinateFieldIdxs = [ siteLngFldIdx, siteLatFldIdx ]
      } else if (targetBundle?.type === 'sector') {
        coordinateFieldIdxs = [ sectorLngFldIdx, sectorLatFldIdx, sectorDXFldIdx, sectorDYFldIdx ]
      }

      let modified = false
      let coordinateChanges = 0
      for (let i = 0; i < tagrgetElement.length; ++i) {
        if ((!targetModified?.[i] || coordinateFieldIdxs.includes(i)) &&
          tagrgetElement[i] !== sourceBundle.element[i]) {
          tagrgetElement[i] = sourceBundle.element[i]
          modified = true
          if (coordinateFieldIdxs.includes(i)) {
            coordinateChanges++
            if (targetModified?.[i]) {
              targetModified[i] = false
            }
          }
        }
      }
      if (modified) {
        if (coordinateChanges === 1) {
          // manual modification
          return
        }
        targetBundle.element = [ ...tagrgetElement ]
        if (targetModified) {
          if (!targetModified.find((val) => val)) {
            targetBundle.modified = undefined
            setNotModified()
          } else {
            targetBundle.modified = [ ...targetModified ]
          }
        }
        setUpdates(updates + 1)
      }
    }
  }, [
    dispatch, editBundle, bundle, setBundle, inUpdate, refill, path, setNotModified,
    siteLngFldIdx, siteLatFldIdx, sectorLngFldIdx, sectorLatFldIdx,
    sectorDXFldIdx, sectorDYFldIdx, updates, setUpdates, isSaving,
  ])

  useEffect(() => {
    if (bundle && isModified) {
      const checkModified = (b) => {
        if (b.modified) {
          return true
        }
        if (b.children) {
          for (const ch of b.children) {
            if (checkModified(ch)) {
              return true
            }
          }
        }
        return false
      }

      if (!checkModified(bundle)) {
        setNotModified()
      }
    }
  }, [ bundle, isModified, setNotModified ])

  const current = useMemo(() => {
    return bundle
      ? path?.reduce((acc, index) => acc?.children?.[index], bundle)
      : null
  }, [ bundle, path ])

  const [ currentName, setCurrentName ] = useState(current?.name)

  useEffect(() => {
    setCurrentName(current?.name)
  }, [ current ])

  const isDraft = useMemo(() => {
    if (current?.element) {
      if (current?.type === 'site') {
        return CAN_BE_REMOVED.includes(current.element[siteStatusFldIdx])
      } else if (current?.type === 'sector') {
        return CAN_BE_REMOVED.includes(current.element[sectorStatusFldIdx])
      } else if (current?.type === 'baseStation') {
        return CAN_BE_REMOVED.includes(current.element[baseStatusStatusFldIdx])
      }
    }
    return false
  }, [ current, siteStatusFldIdx, sectorStatusFldIdx, baseStatusStatusFldIdx ])

  const isRemoved = useMemo(() => {
    if (current?.element) {
      if (current?.type === 'site') {
        return current.element[siteStatusFldIdx] === STATUS_REMOVED
      } else if (current?.type === 'sector') {
        return current.element[sectorStatusFldIdx] === STATUS_REMOVED
      } else if (current?.type === 'baseStation') {
        return current.element[baseStatusStatusFldIdx] === STATUS_REMOVED
      }
    }
    return false
  }, [ current, siteStatusFldIdx, sectorStatusFldIdx, baseStatusStatusFldIdx ])

  const caption = useMemo(() => {
    return current ? `${current.type.charAt(0).toUpperCase() + current.type.slice(1)} Settings` : 'Settings'
  }, [ current ])

  useEffect(() => {
    const label = document.getElementById('panel-caption-label')
    label.innerHTML = caption
  }, [ caption ])

  const doConfirmClose = useCallback(async () => {
    if (!userAccess[EDIT_ELEMENTS] || !isModified) {
      dispatch(cleanUpBundle())
      return true
    }
    return new Promise((resolve) => ask(
      () => {
        setNotModified()
        dispatch(cleanUpBundle())
        resolve(true)
      },
      () => resolve(false),
      null,
      {
        messages: [ 'Cancel all changes?' ],
      },
    ))
  }, [ userAccess, isModified, setNotModified, ask, dispatch ])

  const closeBundleEditor = useCallback(async () => {
    if (await doConfirmClose()) {
      if (confirmClose) {
        delete confirmClose.check
      }
      onClose()
    }
  }, [ doConfirmClose, confirmClose, onClose ])

  useEffect(() => {
    if (confirmClose) {
      confirmClose.check = doConfirmClose
    }
  }, [ confirmClose, doConfirmClose ])

  const onResolve = useCallback(async (doSave) => {
    if (doSave) {
      await dispatch(saveBundle(bundle))
    }
    setNotModified()
  }, [ dispatch, bundle, setNotModified ])

  const onSaveAll = async () => {
    setSaving()

    let nameFldIdx
    let nameFld
    let full
    if (current.type === 'site') {
      nameFld = SITE_NAME_FIELD
      nameFldIdx = siteNameFldIdx
      full = sitesFull
    } else if (current.type === 'sector') {
      nameFld = SECTOR_NAME_FIELD
      nameFldIdx = sectorNameFldIdx
      full = sectorsFull
    }

    if (nameFld && nameFldIdx && full && current.modified) {
      if (nameFldIdx >= 0 && current.modified[nameFldIdx]) {
        const modifiedName = current.element[nameFldIdx]
        const newName = checkConvertValue(
          modifiedName, nameFldIdx, current.fields,
          full, nameFld, full.findIndexById(current.id),
        )
        if (modifiedName !== newName) {
          current.name = newName
          current.element[nameFldIdx] = newName

          current.children.forEach((baseStation) => {
            baseStation.element[baseStationSiteNameFldIdx] = newName
            baseStation.children.forEach((sector) => {
              sector.element[sectorSiteNameFldIdx] = newName
            })
          })
        }
      }
    }

    if (current.type === 'site') {
      extendSiteBundleToMoveSectors(store.getState(), current)
    }
    await dispatch(saveBundle(bundle))
    setNotModified()
    dispatch(cleanUpBundle())
    if (confirmClose) {
      delete confirmClose.check
    }
    onClose()

    // Update the active marker if the coordinates have changed
    if (current.action === 'UPDATE') {
      const marker = current.type === 'site' ? window.map?._activeSiteMarker : window.map?._activeSectorMarker
      if (marker) {
        const lngFldIdx = current.type === 'site' ? siteLngFldIdx : sectorLngFldIdx
        const latFldIdx = current.type === 'site' ? siteLatFldIdx : sectorLatFldIdx
        if (current.modified[lngFldIdx] || current.modified[latFldIdx]) {
          marker
            .setLatLng({ lat: current.element[latFldIdx], lng: current.element[lngFldIdx] })
        }
      }
    }

    setNotSaving()
  }

  useEffect(() => {
    if (resolve) {
      if (isModified) {
        ask(
          () => onResolve(true).then(resolve),
          () => onResolve(false).then(resolve),
          null,
          {
            textYesBtn: 'Save',
            textNoBtn: 'Discard',
            messages: [ 'You have unsaved changes.', 'Should they be saved before proceed?' ],
          },
        )
      } else {
        resolve()
      }
    }
  }, [ resolve, isModified, onResolve, ask ])

  const onSelectComplaint = useCallback((path) => {
    dispatch(setBundlePath(path))
  }, [ dispatch ])

  const currentChanged = useCallback((fieldIndex) => {
    if (!current.modified) {
      current.modified = new Array(current.element.length).fill(false)
      current.action = ACTION_UPDATE
      setModified()
    }
    current.modified[fieldIndex] = true
    if (current.type === 'site' && fieldIndex === siteNameFldIdx) {
      current.name = current.element[siteNameFldIdx]
      current.children.forEach((baseStation) => {
        baseStation.element[baseStationSiteNameFldIdx] = current.element[siteNameFldIdx]
        baseStation.children.forEach((sector) => {
          sector.element[sectorSiteNameFldIdx] = current.element[siteNameFldIdx]
        })
      })
      // setBundle({ ...bundle })
    } else if (current.type === 'baseStation' && fieldIndex === baseStationNameFldIdx) {
      current.name = current.element[baseStationNameFldIdx]
      current.children.forEach((sector) => {
        sector.element[sectorBaseStationNameFldIdx] = current.element[baseStationNameFldIdx]
      })
      // setBundle({ ...bundle })
    } else if (current.type === 'sector' && fieldIndex === sectorNameFldIdx) {
      current.name = current.element[sectorNameFldIdx]
      // setBundle({ ...bundle })
    }
    setCurrentName(current.name)
  }, [
    current, setModified, siteNameFldIdx, sectorNameFldIdx,
    baseStationNameFldIdx, baseStationSiteNameFldIdx,
    sectorBaseStationNameFldIdx, sectorSiteNameFldIdx,
    setCurrentName,
  ])

  const Properties = useMemo(() => {
    return active && current?.element
      ? (
        <NetworkItemProperties
          path={current.path.join('-')}
          data={current.element}
          fields={current.fields}
          complaints={current.complaints}
          onSelectComplaint={onSelectComplaint}
          onChange={currentChanged}
          readOnly={(!isMyProject && !isDefaultProject) || !userAccess[EDIT_ELEMENTS] || current.type === 'complaint'}
          isDraft={isDraft}
          isRemoved={isRemoved}
          nameFieldIndex={current.type === 'site'
            ? siteNameFldIdx
            : current.type === 'baseStation'
              ? baseStationNameFldIdx
              : current.type === 'sector'
                ? sectorNameFldIdx
                : -1}
          customEditableCheck={(id) => {
            if (current.type === 'sector' && id === SECTOR_NAME_FIELD &&
                current.element[sectorStatusFldIdx] === STATUS_ACTIVE) {
              return false
            } else if (current.type === 'site' && id === SITE_NAME_FIELD &&
                current.element[siteStatusFldIdx] === STATUS_ACTIVE) {
              return false
            } else if (current.type === 'baseStation' && id === RBS_NAME_FIELD &&
                current.element[baseStatusStatusFldIdx] === STATUS_ACTIVE) {
              return false
            }
            return true
          }}
        />
        )
      : null
  }, [
    active, baseStatusStatusFldIdx, currentChanged, isDefaultProject, isDraft, isMyProject, isRemoved,
    onSelectComplaint, sectorStatusFldIdx, siteStatusFldIdx, userAccess, siteNameFldIdx, sectorNameFldIdx,
    baseStationNameFldIdx,
    current?.path, current?.element, current?.type, current?.fields, current?.complaints,
  ])

  if (!active || !current) {
    return null
  }

  return (
    <div className="network-item-editor-content">
      <div className="network-item-editor-container">
        {Properties}
      </div>
      <div className='network-item-editor-footer'>
        <SecondaryButton onClick={closeBundleEditor} text="Close" />
        {bundle && bundle.type === 'site' && userAccess[EDIT_ELEMENTS] && (
          <PrimaryButton onClick={onSaveAll} disabled={!isModified || !current.name} text="Save" />
        )}
      </div>
      {renderConfirm()}
      {detailsCaptionDiv && ReactDOM.createPortal(
        <NetworkItemBundleBreadcrumbs
          bundle={bundle}
          currentId={current.id}
          currentName={currentName}
        />,
        detailsCaptionDiv,
      )}
      {detailsContentDiv && ReactDOM.createPortal(
        <NetworkItemBundleTree
          bundle={bundle}
          inUpdate={inUpdate}
          readOnly={(!isMyProject && !isDefaultProject) || !userAccess[EDIT_ELEMENTS]}
        />,
        detailsContentDiv,
      )}
    </div>
  )
}

export default NetworkItemEditor
