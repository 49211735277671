import React from 'react'
import Tooltip from 'react-tooltip-lite'
import { buildClassList } from '../utils/classes'

import './MetricsPanel.css'

const BASE_CLASS = 'metrics-panel'

const makeRow = (index, name, availability) => (
  <li key={index}>
    <MetricsIndicator availability={availability}/>
    <span>{name}</span>
  </li>
)

const makeTitle = (service, subServices) => {
  return (
    <div>
      <h5>{service}</h5>
      {subServices
        ? <ul>
            {Object.values(subServices).map(({ name, availability }, index) => makeRow(index, name, availability))}
          </ul>
        : ''}
    </div>
  )
}

const MetricsIndicator = ({ availability, title }) => (
  <div className={buildClassList(`${BASE_CLASS}__indicator`, { [availability]: true })} title={title} />
)

const MetricsItem = ({ expanded, data: { id, name, availability, subServices } }) => {
  const title = makeTitle(name, subServices)
  const hideArrow = !expanded && id === 'atoll'
  return (
    <Tooltip
      className={`capex-metric-indicators ${hideArrow ? 'capex-metric-indicators-arrow' : ''}`}
      direction='up'
      arrow={!hideArrow}
      content={title}
    >
      {hideArrow && <div className='arrow' />}
      <MetricsIndicator availability={availability}/>
      {expanded && <label>{name}</label>}
    </Tooltip>
  )
}

const MetricsPanel = ({ expanded, data }) => {
  return (
    <div className={buildClassList(BASE_CLASS, { expanded })}>
      {Object.entries(data).map(([ key, item ]) => (
        <MetricsItem key={key} expanded={expanded} data={item} />
      ))}
    </div>
  )
}

export default MetricsPanel
