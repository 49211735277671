import React from 'react'
import {
  DocumentCard, DocumentCardDetails, DocumentCardPreview, DocumentCardTitle, DocumentCardType, getTheme, Modal,
  IconButton, FontWeights, mergeStyleSets,
} from '@fluentui/react'

import './SelectOperation.css'

const theme = getTheme()
const { palette, fonts } = theme

const previewPropsUsingIcon = (iconName) => ({
  previewImages: [
    {
      previewIconProps: {
        iconName,
        styles: { root: { fontSize: fonts.superLarge.fontSize, color: palette.white } },
      },
      width: 144,
    },
  ],
  styles: { previewIcon: { backgroundColor: palette.themePrimary } },
})

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
}

const cancelIcon = { iconName: 'Cancel' }

const contentStyles = mergeStyleSets({
  header: [
    theme.fonts.xxLarge,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
})

const SelectOperation = ({ className, caption, onClose, operations, actions, children }) => (
  <Modal
    isOpen={true}
    isBlocking={false}
    onDismiss={onClose}
    containerClassName={'flex'}
    className={className}
  >
    <div className="select-operation-content">
      <div className={contentStyles.header} style={{ flex: 0 }}>
        <span>{caption}</span>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          onClick={onClose}
        />
      </div>
      <div className="select-operation-subheader">
        {children}
      </div>
      <div className="select-operation-container">
        {operations.filter(({ hidden }) => !hidden).map((operation, index) => (
          <DocumentCard
            key={index}
            className="select-operation-card"
            type={DocumentCardType.compact}
            onClick={actions[index]}
          >
            <DocumentCardPreview {...previewPropsUsingIcon(operation.icon)} className="select-operation-icon" />
            <DocumentCardDetails>
              <DocumentCardTitle
                title={operation.title}
                className="select-operation-title"
              />
              <DocumentCardTitle
                title={operation.description}
                showAsSecondaryTitle
                className="select-operation-description"
              />
            </DocumentCardDetails>
          </DocumentCard>
        ))}
      </div>
    </div>
  </Modal>
)

export default SelectOperation
