import XLSX from 'xlsx'
import { stringify } from 'csv-stringify/sync'
import { parse } from 'csv-parse/sync'
import * as JSZip from 'jszip'
import { DATE_FORMAT } from '../components/Tables/utils'
import {
  COMPLAINT_LAT_FIELD,
  COMPLAINT_LNG_FIELD,
  COMPLAINT_TYPE_P_FIELD,
  COMPLAINT_TYPE_S_FIELD,
} from '../constants/network'
import { DATA_TYPES } from '../constants/common'
import { getIdByDataType, isVectorMap } from '../features/vector/utils'
import { EXPORT_FORMAT } from '../constants/menus'
import { MID_MIF_EXT } from '../constants/geo'
import { encode } from './base64'
import { dataToMidMif } from './convert'
import { saveAs } from './FileSaver'
import { errorFixing } from './format'

export const NESTED_HEADER_DELIMITER = '//'

const uriCSV = 'data:text/csv;base64,'
const uriText = 'data:text/plain;base64,'
const uriKML = 'data:application/vnd.google-earth.kml+xml;,'

const ZIP_OPTIONS = {
  type: 'blob',
  compression: 'DEFLATE',
  compressionOptions: {
    level: 5,
  },
}

const MAX_XLSX_CELL_LENGTH = 32767

function encodeToXml (_) {
  return (_ == null ? '' : _.toString()).replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
}

[ 'clipboard-read', 'clipboard-write' ].forEach((permission) => {
  navigator.permissions.query({ name: permission }).then((result) => {
    if (result.state === 'granted' || result.state === 'prompt') {
      console.info(permission, 'granted')
    } else {
      console.warn(permission, 'NOT granted')
    }
  }).catch((error) => {
    console.warn(permission, 'ERROR:', error?.message)
  })
})

export const downloadURI = (uri, name) => {
  const link = document.createElement('a')
  link.download = name
  link.href = uri
  link.click()
}

const updateClipboard = (newClip) => {
  navigator.clipboard.writeText(newClip)
    .catch((err) => console.error('Clipboard is not set!', err))
}

const tableToKmlData = (data, headers, dataType) => {
  if (!Array.isArray(data) || !Array.isArray(headers)) {
    errorFixing(new Error('Error export table to KML. Data is not correct'))
    return null
  }
  const { idLat, idLng, idName, idDescription } = getIdForExport(dataType)
  const latIdx = headers.findIndex((header) => header.id === idLat)
  const lngIdx = headers.findIndex((header) => header.id === idLng)
  const nameIdx = headers.findIndex((header) => header.id === idName)
  const descriptionIdx = headers.findIndex((header) => header.id === idDescription)
  if (latIdx < 0 || lngIdx < 0) {
    errorFixing(new Error('Error export table to KML. Coordinates not defined'))
    return null
  }
  const geoData = data.map((item) => {
    if (!item[lngIdx] || !item[latIdx]) {
      return null
    }
    const name = nameIdx >= 0 ? `${item[nameIdx]}` : ''
    const nameTag = `<name>${encodeToXml(name)}</name>`
    const description = descriptionIdx >= 0 ? `${item[descriptionIdx]}` : ''
    const descriptionTag = `<description>${encodeToXml(description)}</description>`
    const coordinatesTag = `<coordinates>${item[lngIdx]},${item[latIdx]},0</coordinates>`
    return `<Placemark>\n${nameTag}\n${descriptionTag}\n<Point>\n${coordinatesTag}\n</Point>\n</Placemark>`
  }).filter(Boolean)
  return geoData.join('\n')
}

const buildKml = (name, description, geo) => `<?xml version="1.0" encoding="UTF-8"?>
<kml xmlns="http://www.opengis.net/kml/2.2">
  <Document>
    <name>${name}</name>
    <description>${description}</description>
    ${geo}
  </Document>
</kml>`

export const exportTableData = (
  format, range, table, dataType, needToExport, ignoreWhenExport, nestedHeaders, columns, onExport, label,
) => {
  const hiddenColumns = table.getPlugin('hiddenColumns').getHiddenColumns()
  const sel = table.getSelected()
  if (range && !sel) {
    throw Error('Please select something in the table before copying')
  }
  if (range && sel.length > 1) {
    throw Error('This action won\'t work on multiple selections')
  }
  let shift = 0
  let data = table.getData()
  let headers = table.getColHeader()

  if (nestedHeaders && nestedHeaders.length > 1) {
    headers = nestedHeaders.reduce((res, level) => {
      const levelHeaders = level.reduce((r, header) => {
        if (header.colspan > 1) {
          r = r.concat(Array(header.colspan).fill(header.label || ''))
        } else {
          r.push(header)
        }
        return r
      }, [])
      if (levelHeaders.length !== res.length) {
        return res
      }
      return levelHeaders.map((value, index) => {
        if (!res[index]) {
          return value
        } else {
          return `${res[index]}${NESTED_HEADER_DELIMITER}${value}`
        }
      })
    }, Array(headers.length))
  }
  if (range) {
    let [ [ rowStart, colStart, rowFinish, colFinish ] ] = sel
    rowStart = Math.max(rowStart, 0)
    colStart = Math.max(colStart, 0)
    data = table.getData(rowStart, colStart, rowFinish, colFinish)
    if (colStart > colFinish) {
      [ colStart, colFinish ] = [ colFinish, colStart ]
    }
    shift = colStart
    headers = headers.slice(colStart, colFinish + 1)
  }

  // Check objects
  data?.forEach((row) => {
    row?.forEach((value, index) => {
      if (value instanceof Object) {
        const stringValue = value.data ? JSON.stringify(value.data) : JSON.stringify(value)
        row[index] = stringValue
      }
    })
  })

  const filterFn = (_, index) => {
    if (needToExport && needToExport.includes(index + shift)) {
      return true
    }
    if (ignoreWhenExport && ignoreWhenExport.includes(index + shift)) {
      return false
    }
    return !hiddenColumns.includes(index + shift)
  }

  if (!headers?.filter(filterFn)?.length) {
    throw Error('No data selected. Please ensure the correct data is selected in the table before exporting')
  }

  if (format === EXPORT_FORMAT.XLSX) {
    const name = isVectorMap(dataType) ? label : dataType
    tableToExcel(headers, data, filterFn, name, `${name}.xlsx`)
  } else if (format === EXPORT_FORMAT.CSV) {
    tableToCSV(headers, data, filterFn, `${dataType}.csv`)
  } else if (format === EXPORT_FORMAT.MIF_MID || format === EXPORT_FORMAT.KML || format === EXPORT_FORMAT.KMZ) {
    const sortHeaders = headers.map((_, index) => columns[table.toPhysicalColumn(index)])
    if (isVectorMap(dataType)) {
      // Експорт векторної карти
      const id = getIdByDataType(dataType)
      onExport && onExport(sortHeaders, data, filterFn, id, format)
    } else {
      // Експорт таблиці
      const name = dataType
      const description = dataType
      switch (format) {
        case EXPORT_FORMAT.MIF_MID: {
          tableToMidMif(sortHeaders, data, filterFn, dataType)
          break
        }
        case EXPORT_FORMAT.KMZ: {
          const geoData = tableToKmlData(data, sortHeaders, dataType)
          if (geoData) {
            const kmlData = buildKml(name, description, geoData)
            dataToZip(kmlData, name, 'kml', 'kmz')
          }
          break
        }
        case EXPORT_FORMAT.KML: {
          const geoData = tableToKmlData(data, sortHeaders, dataType)
          if (geoData) {
            const kmlData = buildKml(name, description, geoData)
            kmlToFile(kmlData, `${name}.kml`)
          }
          break
        }
        default:
      }
    }
  } else {
    // format === 'txt' (TAB-separated)
    const headersBuffer = headers.filter(filterFn).join('\t')
    const dataBuffer = data.map((row) => row.filter(filterFn).join('\t')).join('\r\n')
    updateClipboard(`${headersBuffer}\r\n${dataBuffer}`)
  }
  return `Table data has been exported [${data.length} rows x ${headers.filter(filterFn).length} columns]`
}

const MAX_CELLS_SCHEET = 3000000

const writeBook = (wb, fileName) => XLSX.writeFile(wb, fileName, { compression: true })

export const tableToExcel = (headers, cells, hidden, name, fileName, sectionSize) => {
  let resMessage = null
  const wb = XLSX.utils.book_new()
  let indexSheet = 1
  if (!cells.length) {
    const ws = XLSX.utils.aoa_to_sheet([ headers.filter(hidden) ])
    XLSX.utils.book_append_sheet(wb, ws, name)
  } else {
    let rowsInWorksheet = Math.floor(MAX_CELLS_SCHEET / headers.length)
    if (sectionSize) {
      rowsInWorksheet = Math.round(rowsInWorksheet / sectionSize) * sectionSize
    }
    for (let numRows = 0; numRows < cells.length; numRows = numRows + rowsInWorksheet) {
      let ws = XLSX.utils.aoa_to_sheet([ headers.filter(hidden) ])
      // eslint-disable-next-line no-loop-func
      const rows = cells.slice(numRows, numRows + rowsInWorksheet).map((row) => {
        return row.filter(hidden).map((cell) => {
          if (cell?.length >= MAX_XLSX_CELL_LENGTH) {
            if (!resMessage) {
              resMessage = `A value that was too long was truncated to the size of ${MAX_XLSX_CELL_LENGTH}`
            }
            return cell.substring(0, MAX_XLSX_CELL_LENGTH - 3) + '...'
          }
          return cell
        })
      })
      console.info(`Filling sheet ${indexSheet} with ${rows.length} rows...`)
      ws = XLSX.utils.sheet_add_aoa(ws, rows, { origin: -1 })
      const nameSheet = indexSheet === 1 ? name : `${name} (${indexSheet})`
      XLSX.utils.book_append_sheet(wb, ws, nameSheet)
      indexSheet++
    }
  }
  console.info('Saving workbook...')
  writeBook(wb, fileName)
  return resMessage
}

export const tablesToExcel = (sheets, fileName) => {
  const wb = XLSX.utils.book_new()
  for (const sheet of sheets) {
    const [ headers, cells, name ] = sheet
    let ws = XLSX.utils.aoa_to_sheet([ headers ])
    if (cells.length) {
      ws = XLSX.utils.sheet_add_aoa(ws, cells, { origin: -1 })
    }
    XLSX.utils.book_append_sheet(wb, ws, name)
  }
  writeBook(wb, fileName)
}

export const plainTextToExcel = (text, name, fileName) => {
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.aoa_to_sheet(text.split('\n').map((row) => row.split('\t')))
  XLSX.utils.book_append_sheet(wb, ws, name)
  writeBook(wb, fileName)
}

export const tableToCSV = (headers, cells, hidden, fileName) => {
  const headersBuffer = stringify([ headers.filter(hidden) ])
  const dataBuffer = stringify(cells.map((row) => row.filter(hidden)))
  const resUri = `${uriCSV}${encode(`\ufeff${headersBuffer}${dataBuffer}`)}`
  downloadURI(resUri, fileName)
}

export const exportToFile = (data, fileName) => {
  const resUri = `${uriText}${encode(`${data}`)}`
  downloadURI(resUri, fileName)
}

const getIdCoordinate = (dataType) => {
  switch (dataType) {
    case DATA_TYPES.COMPLAINTS: return { idLat: COMPLAINT_LAT_FIELD, idLng: COMPLAINT_LNG_FIELD }
    default: return {}
  }
}

const getIdForExport = (dataType) => {
  switch (dataType) {
    case DATA_TYPES.COMPLAINTS: return {
      idLat: COMPLAINT_LAT_FIELD,
      idLng: COMPLAINT_LNG_FIELD,
      idName: COMPLAINT_TYPE_P_FIELD,
      idDescription: COMPLAINT_TYPE_S_FIELD,
    }
    default: return {}
  }
}

export const dataToZip = (data, nameFile, extFile = 'txt', extZip = 'zip', callbackFailed, callbackCompleted) => {
  const zip = new JSZip()
  if (Array.isArray(extFile)) {
    extFile.forEach((ext, index) => {
      zip.file(`${nameFile}.${ext}`, data[index])
    })
  } else {
    zip.file(`${nameFile}.${extFile}`, data)
  }
  zip.generateAsync(ZIP_OPTIONS)
    .then(function (content) {
      const res = saveAs(content, `${nameFile}.${extZip}`)
      if (res.readyState === 2) {
        callbackCompleted && callbackCompleted()
      } else {
        callbackFailed && callbackFailed()
      }
    }).catch(() => callbackFailed && callbackFailed())
}

export const kmlToFile = (dataBuffer, fileName) => {
  const resUri = `${uriKML}${dataBuffer}`
  downloadURI(resUri, fileName)
}

export const tableToMidMif = (headersAll, cells, hidden, dataType) => {
  const headers = headersAll.filter(hidden)
  const data = cells.map((row) => row.filter(hidden))
  const { idLat, idLng } = getIdCoordinate(dataType)
  if (!idLng || !idLat) {
    return
  }
  const { mid, mif } = dataToMidMif(data, headers, idLat, idLng, undefined)
  dataToZip([ mid, mif ], dataType, MID_MIF_EXT, 'zip')
}

export const normalizeDouble = (value) => {
  const str = value && value.replace ? value.replace(',', '.') : value
  const num = Number(str)
  return isNaN(num) ? 0 : num
}

export const readFileContentCSV = async (file) => parse(await file.text())

export const readFileContentXLSX = async (file, importRawNumbers) => {
  const data = await file.arrayBuffer()
  const workbook = XLSX.read(data, { dateNF: DATE_FORMAT })
  let result = null
  for (const sheetName of workbook.SheetNames) {
    const sheet = workbook.Sheets[sheetName]
    const csv = XLSX.utils.sheet_to_csv(sheet, importRawNumbers && { rawNumbers: importRawNumbers })
    if (result && result.length > 0) {
      const data = parse(csv)
      if (result[0]?.length === data[0]?.length) {
        result = result.concat(data.slice(1))
      }
    } else {
      result = parse(csv)
    }
  }
  return result
}
