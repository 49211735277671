import React, { useCallback, useMemo, useState } from 'react'
import {
  FontWeights, getTheme, IconButton, mergeStyleSets, Modal, Stack, StackItem,
} from '@fluentui/react'
import moment from 'moment'
import { DefaultButton, PrimaryButton } from '../common/Button'

import './SelectPeriod.css'
import DatePicker from '../common/DatePicker'
import { DATE_FORMAT } from '../Tables/utils'

const theme = getTheme()

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
}

const cancelIcon = { iconName: 'Cancel' }

const contentStyles = mergeStyleSets({
  header: [
    theme.fonts.xxLarge,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
})

const SelectPeriod = ({ onCancel, onSubmit }) => {
  const [ fromDate, setFromDate ] = useState(new Date())
  const [ toDate, setToDate ] = useState(new Date())

  const isSelectFileDisabled = useMemo(() => {
    return !fromDate || !toDate || moment(fromDate).isAfter(moment(toDate))
  }, [ fromDate, toDate ])

  const handleOnOk = useCallback(() => {
    onSubmit({
      fromDate: moment(fromDate).format(DATE_FORMAT),
      toDate: moment(toDate).format(DATE_FORMAT),
    })
  }, [ onSubmit, fromDate, toDate ])

  return (
    <Modal
      width={520}
      containerClassName="top-most"
      isOpen={true}
      isBlocking={true}
    >
      <div className={contentStyles.header}>
        <span>{'Select Period'}</span>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          onClick={onCancel}
        />
      </div>
      <Stack className="modal-content-select-period">
        <StackItem>
          <DatePicker
            label="From date"
            value={fromDate}
            onSelectDate={setFromDate}
          />
        </StackItem>
        <StackItem>
          <DatePicker
            label="To date"
            value={toDate}
            onSelectDate={setToDate}
          />
        </StackItem>
        <StackItem className='financials-buttons'>
          <DefaultButton className="dialog-button-select-period" text="Cancel" onClick={onCancel} />
          <PrimaryButton
            className="dialog-button-select-period"
            text="Select File"
            onClick={handleOnOk}
            disabled={isSelectFileDisabled}
          />
        </StackItem>
      </Stack>
    </Modal>
  )
}

export default SelectPeriod
