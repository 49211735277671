import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import simplify from '@turf/simplify'
import { point } from '@turf/helpers'
import api from '../../api'
import {
  columnsResultRate, defaultTemplate, ELEMENTS, EXECUTION_STATE, TITLE_STATE,
} from '../../components/CompositeIndex/constants'
import {
  selectBaseStationFields, selectBaseStations, selectBaseStationsFull, selectSectorFields, selectSectors,
  selectSectorsFull, selectSiteFields, selectSites, selectSitesFull, updateBundlePrepare,
} from '../network/networkSlice'
import { calculateStatistic } from '../../components/CompositeIndex/utils'
import { activeProjectId, selectIsMyProject } from '../projects/projectsSlice'
import { BUNDLE_TREE_LEVELS } from '../../api/network'
import {
  NETWORK_ELEMENT_COUNT,
  PROJECT_FIELD_KPI, RBS_NAME_FIELD, RBS_SITE_FIELD, RBS_SITE_NAME_FIELD, RBS_STATUS_FIELD, SECTOR_LAT_FIELD,
  SECTOR_LNG_FIELD, SECTOR_NAME_FIELD, SECTOR_STATUS_FIELD, SITE_ID_FIELD, SITE_LAT_FIELD, SITE_LNG_FIELD,
  SITE_NAME_FIELD, SITE_STATUS_FIELD, STATUS_ACTIVE,
} from '../../constants/network'
import { toPolygon } from '../geo/geoSlice'
import { ID_GEO_ZONES_COMPUTATION } from '../../constants/geo'
import { errorFixing } from '../../utils/format'
import { EVENT_TYPE, errorEventLog, wrapByEventLog } from '../eventLog/eventLogSlice'
import { findIndex } from '../network/indexing'
import { binaryIncludes, binaryIncludesCmp } from '../../utils/math'
import { calcChanges } from '../../utils/bundle'
import { COMPOSITE_INDEXES_SAVE } from '../../constants/access'
import { selectUserAccess } from '../login/loginSlice'

const initialState = {
  loading: false,
  saving: false,
  deleting: false,
  needReloadToCreateProjectFromDefault: false,
  templateList: [],
  toTemplate: null,
  optionsElements: [],
  selectedTemplate: undefined,
  kpi: {
    sites: [],
    sectors: [],
    rbs: [],
  },
  kpiChanged: false,
  statisticsCalculated: false,
  rateCalculated: false,
  rateCalculationCompleted: false,
  resources: {
    sites: null,
    rbs: null,
    sectors: null,
  },
  initialResources: {
    sites: null,
    rbses: null,
    sectors: null,
  },
  statistic: {},
  allKpiElements: {},
  executionState: EXECUTION_STATE.INIT,
  rates: null,
  rawRates: null,
  sortedRates: null,
  resourcesProcessed: null,
  ratesBundle: null,
  savingRates: null,
  loadingResourceStatus: null,
}

const loading = (state) => {
  state.loading = true
}

const loadingResourceSites = (state) => {
  state.loadingResourceStatus = { ...state.loadingResourceStatus, current: 2 }
  state.resources.sites = {}
}

const loadingResourceRBS = (state) => {
  state.loadingResourceStatus = { ...state.loadingResourceStatus, current: 2 }
  state.resources.rbs = {}
}

const loadingResourceSector = (state) => {
  state.loadingResourceStatus = { ...state.loadingResourceStatus, current: 2 }
  state.resources.sectors = {}
}

const saving = (state) => {
  state.saving = true
}
const deleting = (state) => {
  state.deleting = true
}

const doLoadTemplateList = createAsyncThunk(
  'compositeIndex/getTemplates',
  api.compositeIndex.getTemplateList,
)

const doSaveTemplate = createAsyncThunk(
  'compositeIndex/saveTemplate',
  api.compositeIndex.saveTemplate,
)

const doCreateTemplate = createAsyncThunk(
  'compositeIndex/createTemplate',
  api.compositeIndex.createTemplate,
)

const doDeleteTemplate = createAsyncThunk(
  'compositeIndex/deleteTemplate',
  api.compositeIndex.deleteTemplate,
)

const doLoadDataSitePeriod = createAsyncThunk(
  'compositeIndex/loadDataSite',
  api.compositeIndex.loadDataSite,
)

const doLoadDataRBSPeriod = createAsyncThunk(
  'compositeIndex/loadDataRBS',
  api.compositeIndex.loadDataRBS,
)

const doLoadDataSectorPeriod = createAsyncThunk(
  'compositeIndex/loadDataSector',
  api.compositeIndex.loadDataSector,
)

export const compositeIndexSlice = createSlice({
  name: 'compositeIndex',
  initialState,
  reducers: {
    setTemplate: (state, action) => {
      const templates = state.templateList ?? []
      state.selectedTemplate = action.payload || templates.find((item) => item.key === 'default')
    },
    setTemplateById: (state, action) => {
      const templates = state.templateList ?? []
      const id = action.payload
      if (id) {
        state.selectedTemplate = templates.find((item) => item.key === id) ??
          templates.find((item) => item.key === 'default')
      }
    },
    setKPITemplate: (state, action) => {
      state.kpi = action.payload
    },
    setOptionsElements: (state, action) => {
      state.optionsElements = action.payload
    },
    setElementKPI: (state, action) => {
      const { elementKey, kpiOptions } = action.payload
      if (elementKey && Array.isArray(kpiOptions)) {
        state.kpi[elementKey] = kpiOptions
        state.kpiChanged = true
      }
    },
    setStatistic: (state, action) => {
      state.statistic = action.payload
      state.statisticsCalculated = false
    },
    setRawRates: (state, action) => {
      state.rawRates = action.payload
    },
    resetStatistic: (state) => {
      state.statistic = {}
    },
    setStatisticsCalculated: (state, action) => {
      state.statisticsCalculated = action.payload
    },
    setRateCalculationCompleted: (state, action) => {
      state.rateCalculationCompleted = action.payload
    },
    setExecutionState: (state, action) => {
      state.executionState = action.payload
    },
    setAllKpiElements: (state, action) => {
      state.allKpiElements = action.payload
    },
    setRates: (state, action) => {
      state.rates = action.payload
    },
    setRatesBundle: (state, action) => {
      state.ratesBundle = action.payload
    },
    setSortedRates: (state, action) => {
      state.sortedRates = action.payload
    },
    setResourcesProcessed: (state, action) => {
      state.resourcesProcessed = action.payload
    },
    setSavingRates: (state, action) => {
      state.savingRates = action.payload
    },
    setLoadingResourceStatus: (state, action) => {
      if (action.payload === null) {
        state.loadingResourceStatus = null
      } else {
        state.loadingResourceStatus = {
          ...state.loadingResourceStatus,
          ...action.payload,
        }
      }
    },
    setNeedReloadToCreateProjectFromDefault: (state, action) => {
      state.needReloadToCreateProjectFromDefault = action.payload
    },
    setResources: (state, action) => {
      const { elementKey, resource, months } = action.payload
      state.resources[elementKey] = { months, ...resource }
    },
    setInitResources: (state, action) => {
      const { elementKey, resource, resources } = action.payload
      if (elementKey) {
        state.initialResources[elementKey] = resource
      } else if (resources && resources.sites && resources.rbses && resources.sectors) {
        state.initialResources = resources
      }
    },
  },
  extraReducers: (builder) => builder
    .addCase(doLoadTemplateList.pending, loading)
    .addCase(doLoadTemplateList.fulfilled, (state, action) => {
      state.loading = false
      state.ready = true
      const templates = action.payload ?? []
      const list = Array.isArray(templates) ? templates.map(extractAttribute) : []
      state.templateList = [ defaultTemplate, ...list ]
    })
    .addCase(doSaveTemplate.pending, saving)
    .addCase(doSaveTemplate.fulfilled, (state) => {
      state.saving = false
      state.kpiChanged = false
    })
    .addCase(doCreateTemplate.pending, saving)
    .addCase(doCreateTemplate.fulfilled, (state, action) => {
      const id = action.payload?.id
      state.saving = false
      state.kpiChanged = false
      if (id) {
        state.toTemplate = { id }
      }
    })
    .addCase(doDeleteTemplate.pending, deleting)
    .addCase(doDeleteTemplate.fulfilled, (state) => {
      state.deleting = false
      state.kpiChanged = false
      state.loadingResourceStatus = null
      state.toTemplate = { id: null }
    })
    .addCase(doLoadDataSitePeriod.pending, loadingResourceSites)
    .addCase(doLoadDataSitePeriod.fulfilled, (state) => {
      state.loadingResourceStatus = { ...state.loadingResourceStatus, current: 3 }
    })
    .addCase(doLoadDataRBSPeriod.pending, loadingResourceRBS)
    .addCase(doLoadDataRBSPeriod.fulfilled, (state) => {
      state.loadingResourceStatus = { ...state.loadingResourceStatus, current: 3 }
    })
    .addCase(doLoadDataSectorPeriod.pending, loadingResourceSector)
    .addCase(doLoadDataSectorPeriod.fulfilled, (state) => {
      state.loadingResourceStatus = { ...state.loadingResourceStatus, current: 3 }
    }),
})

const {
  setStatistic, setStatisticsCalculated, setRatesBundle, setResources, setInitResources, setRawRates,
  setNeedReloadToCreateProjectFromDefault,
} = compositeIndexSlice.actions

export const {
  setTemplateById, setKPITemplate, setOptionsElements, setElementKPI, resetStatistic, setRateCalculationCompleted,
  setExecutionState, setAllKpiElements, setRates, setSortedRates, setResourcesProcessed, setSavingRates,
  setLoadingResourceStatus, // Стан завантаження ресурсів
} = compositeIndexSlice.actions

export const selectTemplateList = (state) => state.compositeIndex.templateList

export const selectedTemplate = (state) => state.compositeIndex.selectedTemplate
export const selectToTemplate = (state) => state.compositeIndex.toTemplate
export const selectKPITemplate = (state) => state.compositeIndex.kpi
export const selectKPIChanged = (state) => state.compositeIndex.kpiChanged
export const selectStatistic = (state) => state.compositeIndex.statistic
export const selectOptionsElement = (state) => state.compositeIndex.optionsElements
export const selectExecutionState = (state) => state.compositeIndex.executionState
export const selectAllKpiElements = (state) => state.compositeIndex.allKpiElements

export const selectStatisticsCalculated = (state) => state.compositeIndex.statisticsCalculated
export const selectRateCalculated = (state) => state.compositeIndex.rateCalculated
export const selectRateCalculationCompleted = (state) => state.compositeIndex.rateCalculationCompleted
export const selectRates = (state) => state.compositeIndex.rates
export const selectRawRates = (state) => state.compositeIndex.rawRates
export const selectRatesBundle = (state) => state.compositeIndex.ratesBundle
export const selectSortedRates = (state) => state.compositeIndex.sortedRates
export const selectResourcesProcessed = (state) => state.compositeIndex.resourcesProcessed
export const selectSavingRates = (state) => state.compositeIndex.savingRates
export const selectLoadingResourceStatus = (state) => state.compositeIndex.loadingResourceStatus
export const selectResources = (state) => state.compositeIndex.resources
const selectResourceSites = (state) => state.compositeIndex.resources.sites
const selectResourceRBS = (state) => state.compositeIndex.resources.rbs
const selectResourceSectors = (state) => state.compositeIndex.resources.sectors

const selectInitialResourceSites = (state) => state.compositeIndex.initialResources.sites
const selectInitialResourceRBSes = (state) => state.compositeIndex.initialResources.rbses
const selectInitialResourceSectors = (state) => state.compositeIndex.initialResources.sectors

const selectTemplatesLoading = (state) => state.compositeIndex.loading

const selectTemplateSaving = (state) => state.compositeIndex.saving
const selectTemplateDeleting = (state) => state.compositeIndex.deleting
export const selectNeedReloadToCreateProjectFromDefault =
  (state) => state.compositeIndex.needReloadToCreateProjectFromDefault

const compareRBS = (a, b) => {
  const result = a.siteName.localeCompare(b.siteName)
  return result === 0 ? a.rbsName.localeCompare(b.rbsName) : result
}

export const initCompositeIndex = (stateInit) => async (dispatch) => {
  let nextState = null
  try {
    switch (stateInit) {
      case EXECUTION_STATE.INIT: {
        nextState = EXECUTION_STATE.INIT_RESET
        break
      }
      case EXECUTION_STATE.INIT_RESET: {
        nextState = EXECUTION_STATE.INIT_DATA
        await dispatch(resetStatistic())
        break
      }
      case EXECUTION_STATE.INIT_DATA: {
        nextState = EXECUTION_STATE.INIT_STATISTIC
        await dispatch(initSources)
        break
      }
      case EXECUTION_STATE.INIT_STATISTIC: {
        nextState = EXECUTION_STATE.INIT_TEMPLATES
        await dispatch(statisticNetworkCalculation)
        break
      }
      case EXECUTION_STATE.INIT_TEMPLATES: {
        nextState = EXECUTION_STATE.EDIT
        await dispatch(loadTemplates())
        break
      }
      default:
    }
  } catch (error) {
    dispatch(errorEventLog(EVENT_TYPE.compositeIndexStage, error, ` "${TITLE_STATE[stateInit]}"`))
  } finally {
    nextState && dispatch(setExecutionState(nextState))
  }
}

export const startCalculation = (dispatch) => {
  // Скидання попереднього розрахунку
  dispatch(setRatesResult(null))
  dispatch(setSortedRates(null))
  // Запуск розрахунку композитних індексів
  dispatch(setExecutionState(EXECUTION_STATE.CALCULATION))
}

export const loadTemplates = (templateId = 'default') => async (dispatch, getState) => {
  const currentState = getState()
  if (!selectTemplatesLoading(currentState)) {
    await dispatch(doLoadTemplateList())
    return dispatch(setTemplateById(templateId))
  }
}

const TYPES_KPI = {
  sites: 'SITE',
  rbs: 'RBS',
  sectors: 'SECTOR',
}

const REVERT_TYPES = {
  SITE: 'sites',
  RBS: 'rbs',
  SECTOR: 'sectors',
}

export const createTemplate = (nameTemplate, elementKey) => (dispatch, getState) => {
  const currentState = getState()
  if (!selectTemplateSaving(currentState)) {
    const kpi = selectKPITemplate(currentState)?.[elementKey]
    if (!Array.isArray(kpi)) {
      return
    }
    const filteringKPI = {}
    kpi
      .filter((item) => item.weight)
      .forEach((item) => {
        filteringKPI[item.id] = { weight: item.weight, better: item.better }
      })
    const templateKPI = {
      name: nameTemplate,
      type: TYPES_KPI[elementKey],
      template: filteringKPI,
    }
    return dispatch(wrapByEventLog({
      type: EVENT_TYPE.compositeIndexActions,
      details: `Create template "${nameTemplate}"`,
      action: () => dispatch(doCreateTemplate(templateKPI)),
    }))
  }
}

export const saveTemplate = (template, elementKey) => (dispatch, getState) => {
  const currentState = getState()
  if (!selectTemplateSaving(currentState) && template?.key) {
    const kpi = selectKPITemplate(currentState)?.[elementKey]
    if (!Array.isArray(kpi)) {
      return
    }
    const filteringKPI = {}
    kpi
      .filter((item) => item.weight)
      .forEach((item) => {
        filteringKPI[item.id] = { weight: item.weight, better: item.better }
      })
    const templateKPI = {
      name: template.text,
      type: TYPES_KPI[elementKey],
      template: filteringKPI,
    }
    return dispatch(wrapByEventLog({
      type: EVENT_TYPE.compositeIndexActions,
      details: `Save template "${template.text}"`,
      action: () => dispatch(doSaveTemplate({ id: template.key, data: templateKPI })),
    }))
      .then((res) => {
        if (res.meta?.requestStatus === 'fulfilled' && res.meta?.arg?.id) {
          // Перезавантаження шаблонів
          dispatch(loadTemplates(res.meta.arg.id))
        }
      })
  }
}

export const deleteTemplate = (id) => (dispatch, getState) => {
  const currentState = getState()
  if (!selectTemplateDeleting(currentState)) {
    return dispatch(wrapByEventLog({
      type: EVENT_TYPE.compositeIndexActions,
      details: `Delete template "${id}"`,
      action: () => dispatch(doDeleteTemplate(id)),
    }))
  }
}

const extractTemplate = (template) => {
  if (Array.isArray(template)) {
    return template
  }
  const keys = Object.keys(template ?? {})
  return keys.map((key) => ({ id: key, ...template[key] }))
}

const extractAttribute = (item) => ({
  key: item.id,
  text: item.name,
  template: {
    [REVERT_TYPES[item.type]]: extractTemplate(item.template),
  },
})

export const statisticNetworkCalculation = async (dispatch, getState) => {
  const currentState = getState()
  if (!selectStatisticsCalculated(currentState)) {
    await dispatch(setStatisticsCalculated(true))
    const sectorFields = selectSectorFields(currentState)
    const sectorsName = selectInitialResourceSectors(currentState)
    if (Array.isArray(sectorsName) && Array.isArray(sectorFields)) {
      const sectorNameIdx = findIndex(sectorFields, SECTOR_NAME_FIELD)
      const sectors = selectSectors(currentState).getList()
        .filter((item) => binaryIncludes(sectorsName, item[sectorNameIdx]))
      if (sectors) {
        const statistic = calculateStatistic(sectorFields, sectors, dispatch)
        const sitesName = selectInitialResourceSites(currentState)
        const siteFields = selectSiteFields(currentState)
        const siteNameIdx = findIndex(siteFields, SITE_NAME_FIELD)
        const statisticSite = Array.isArray(sitesName)
          ? selectSites(currentState).getList().filter((item) => binaryIncludes(sitesName, item[siteNameIdx])).length
          : undefined
        return dispatch(setStatistic({ ...statistic, statisticSite }))
      }
    }
    return dispatch(setStatisticsCalculated(false))
  }
}

export const setRatesResult = (rates, elementKey, resource, fields) => async (dispatch) => {
  if (rates == null) {
    return dispatch(setRates(null))
  }
  dispatch(setRawRates(rates))
  const [ indCI, indSite, indRBS, indSector ] = columnsResultRate[elementKey]
    .map((field, index) => index ? findIndex(fields, field) : field)
  if (!Array.isArray(resource) || resource.length < 1 || !Array.isArray(fields) || indCI == null) {
    return
  }
  const ratesField = rates
    .map((rate, indexRate) => {
      const row = resource[indexRate]
      if (!row || rate === row[indCI]) {
        return null
      }
      switch (elementKey) {
        case 'sites':
          return {
            sites: {
              [row[indSite]]: {
                attributes: { [indCI]: rate },
              },
            },
          }
        case 'rbs':
          return {
            sites: {
              [row[indSite]]: {
                rbses: {
                  [row[indRBS]]: {
                    attributes: { [indCI]: rate },
                  },
                },
              },
            },
          }
        case 'sectors':
          return {
            sites: {
              [row[indSite]]: {
                rbses: {
                  [row[indRBS]]: {
                    sectors: {
                      [row[indSector]]: {
                        attributes: { [indCI]: rate },
                      },
                    },
                  },
                },
              },
            },
          }
        default:
      }
      return null
    })
    .filter(Boolean)
  dispatch(setRates(ratesField))
}

const setAttribute = (bundle, id, value) => {
  if (!bundle.attributes) {
    bundle.attributes = {}
  }
  bundle.attributes[id] = value
}

const assembly = (bundle, rate, level) => {
  const keyLevel = BUNDLE_TREE_LEVELS[level]
  if (!rate[keyLevel]) {
    if (rate.attributes) {
      const keys = Object.keys(rate.attributes)
      keys.forEach((key) => setAttribute(bundle, key, rate.attributes[key]))
    }
    return
  }
  const keys = Object.keys(rate[keyLevel])
  if (keys.length) {
    if (!bundle[keyLevel]) {
      bundle[keyLevel] = {}
    }
    keys.forEach((key) => {
      if (!bundle[keyLevel][key]) {
        bundle[keyLevel][key] = {}
      }
      assembly(bundle[keyLevel][key], rate[keyLevel][key], level + 1)
    })
  }
}

// Генерування бандлу для збереження композитних індексів
export const buildBundleCompositeIndex = (elementKey) => async (dispatch, getState) => {
  const state = getState()
  const rates = selectRates(state)
  const elements = columnsResultRate[elementKey]
  if (!rates || !elements) {
    return false
  }
  const bundle = {}
  rates.forEach((rate) => {
    assembly(bundle, rate, 0)
  })
  return dispatch(setRatesBundle(bundle.sites))
}

const messageError = 'Composite index rates not calculated'
const errorBundle = { stack: messageError, message: messageError }

const delay = (time) => new Promise((resolve) => setTimeout(resolve, time))

export const saveCompositeIndexes = (elementKey) => async (dispatch, getState) => {
  const rez = await dispatch(buildBundleCompositeIndex(elementKey))
  if (!rez) {
    errorFixing(errorBundle)
    return rez
  }
  const state = getState()
  const projectId = activeProjectId(state)
  const isMyProject = selectIsMyProject(state)
  const userAccess = selectUserAccess(state)
  let current = 0
  const rates = selectRatesBundle(state)
  if (!rates) {
    errorFixing(errorBundle)
    return false
  }
  const sites = Object.keys(rates)
  if (!sites) {
    // setTimeout(() => dispatch(setSavingRates(null)), 500)
    errorFixing(errorBundle)
    return false
  }
  const total = calcChanges(rates)
  await dispatch(wrapByEventLog({
    type: EVENT_TYPE.saveCompositeIndex,
    details: `${total} records for ${elementKey}`,
    action: async () => {
      await dispatch(setSavingRates({ total, current, status: 'in-progress' }))
      try {
        while (sites.length) {
          let portion = 0
          let elementCount = 0
          while (portion < sites.length && elementCount < NETWORK_ELEMENT_COUNT) {
            portion++
            elementCount += calcChanges(rates[sites[portion - 1]])
          }
          const keys = sites.splice(0, portion)
          const packet = {}
          for (const key of keys) {
            packet[key] = rates[key]
          }
          let tryCount = 0
          while (true) {
            let result, error
            try {
              result = await dispatch(updateBundlePrepare({
                activeProjectId: projectId,
                bundle: { sites: packet },
                saveToCache: !isMyProject || !userAccess[COMPOSITE_INDEXES_SAVE]
                  ? () => delay(1)
                  : undefined,
              }))
              if (result && !result.error) {
                break
              }
              error = result?.error?.message || 'Unknown error'
            } catch (err) {
              error = err.message
            }
            console.error(error)
            await dispatch(errorEventLog(EVENT_TYPE.saveCompositeIndex, error, ` (so far ${current}/${total} saved)`))
            if (error === 'Failed to fetch') {
              await dispatch(setSavingRates({ total, current, status: 'overloaded' }))
              const seconds = 10 * (1 << tryCount)
              console.info(`Waiting ${seconds} seconds before retrying...`)
              await new Promise((resolve) => setTimeout(resolve, seconds * 1000))
              tryCount++
            }
          }
          current += calcChanges(packet)
          await dispatch(setSavingRates({ total, current, status: 'in-progress' }))
        }
      } finally {
        setTimeout(() => dispatch(setSavingRates({ total, current, status: 'final' })), 500)
      }
      return current
    },
    extractor: (saved) => `Saved ${saved} records for ${elementKey}`,
  }))
  if (!projectId && total > 0) {
    dispatch(setNeedReloadToCreateProjectFromDefault(true))
  }
  return true
}

const addAttributesToResource = (full, fields, data, fieldsResource, attributes, nameFld, siteNameFld) => {
  const fullList = full.getList()
  if (!Array.isArray(fields) || !Array.isArray(fullList)) {
    console.error('Data project not Array')
    return null
  }
  // Атрибути, яких немає в прийнятих даних, які потрібно заповнити з проєкту
  const addAttributes = []
  // Індекси атрибутів, які наявні прийнятих даних
  const indFieldsResource = attributes.map((attribute) => {
    const indexResource = findIndex(fieldsResource, attribute)
    if (indexResource === -1) {
      addAttributes.push(attribute)
    }
    const indexProject = findIndex(fields, attribute)
    if (indexProject === -1) {
      return -1 // Відсутність у проєкті потрібних атрибутів
    }
    return { indexResource, indexProject }
  })
  // Індекси атрибутів, яких немає в прийнятих даних
  const indFieldsAdd = addAttributes.map((attribute) => findIndex(fields, attribute))
  if (indFieldsAdd.includes(-1) || indFieldsResource.includes(-1)) {
    console.error('Required attributes are not in the project') // Потрібних атрибутів немає в проєкті
    return null
  }
  const nameIdx = findIndex(fieldsResource, nameFld) // Індекс поля назви елемента мережі в прийнятих даних
  const siteNameIdx = siteNameFld ? findIndex(fieldsResource, siteNameFld) : -2
  if (nameIdx === -1 || siteNameIdx === -1) {
    console.error('The required attributes are not in the received data')
    return null
  }
  const fieldsAdd = indFieldsAdd.map((index) => fields[index])
  const fieldsAll = fieldsResource.concat(fieldsAdd) // Збираємо перелік атрибутів елемента мережі
  const indexStart = fieldsResource.length
  const newData = data.map((item) => {
    const elementName = item[nameIdx]
    const siteName = siteNameIdx >= 0 ? item[siteNameIdx] : null
    // Пошук елемента в проєкті
    let listIdx = -1
    if (siteName) {
      const range = full.findRangeByValue(siteName, siteNameFld)
      if (range.length > 0) {
        const found = range.find((ind) => fullList[ind][nameIdx] === elementName)
        if (found !== undefined) {
          listIdx = found
        }
      }
    } else {
      const range = full.findRangeByValue(elementName, nameFld)
      if (range.length > 0) {
        listIdx = range[0]
      }
    }
    if (listIdx >= 0) {
      const element = fullList[listIdx] // Дані з проєкту
      // Доповнення даних із проєкту
      const newItem = item.slice()
      indFieldsResource.forEach(({ indexResource, indexProject }) => {
        newItem[indexResource] = element[indexProject]
      })
      indFieldsAdd.forEach((indField, index) => {
        newItem[indexStart + index] = element[indField]
      })
      return newItem
    } else {
      console.error(`No data for element ${siteName ? `${siteName}:${elementName}` : elementName}`)
    }
    return null
  }).filter(Boolean)
  return { data: newData, fields: fieldsAll }
}

// Доповнення даних за період, яких не вистачає, даними з проєкту користувача
const setProjectAttributes = (res, elementKey) => async (dispatch, getState) => {
  const state = getState()
  const { meta, payload } = res ?? {} // selectResourcesLoaded(state)
  const months = meta?.arg?.months
  const { data, fields: fieldsData } = payload ?? {}
  const attributes = PROJECT_FIELD_KPI[elementKey] // Список полів, яких не вистачає
  if (!Array.isArray(fieldsData) || !Array.isArray(data) || !Array.isArray(attributes) || !months) {
    return
  }
  switch (elementKey) {
    case ELEMENTS.SITES: {
      const full = selectSitesFull(state)
      const fields = selectSiteFields(state)
      const resource = addAttributesToResource(full, fields, data, fieldsData, attributes, SITE_NAME_FIELD)
      if (resource) {
        dispatch(setResources({ resource, months, elementKey }))
      } else {
        dispatch(setResources({ resource: {}, months: null, elementKey }))
      }
      break
    }
    case ELEMENTS.RBS: {
      const full = selectBaseStationsFull(state)
      const fields = selectBaseStationFields(state)
      const resource =
        addAttributesToResource(full, fields, data, fieldsData, attributes, RBS_NAME_FIELD, RBS_SITE_NAME_FIELD)
      if (resource) {
        dispatch(setResources({ resource, months, elementKey }))
      } else {
        dispatch(setResources({ resource: {}, months: null, elementKey }))
      }
      break
    }
    case ELEMENTS.SECTORS: {
      const full = selectSectorsFull(state)
      const fields = selectSectorFields(state)
      const resource = addAttributesToResource(full, fields, data, fieldsData, attributes, SECTOR_NAME_FIELD)
      if (resource) {
        dispatch(setResources({ resource, months, elementKey }))
      } else {
        dispatch(setResources({ resource: {}, months: null, elementKey }))
      }
      break
    }
    default:
  }
}

// Підготовка даних перед розрахунком. Фільтрація за статусом та Computation Zone
const initSources = (dispatch, getState) => {
  const state = getState()
  let poly
  const computationZone = state.geo.zones.children.find(({ id }) => id === ID_GEO_ZONES_COMPUTATION)
  if (computationZone?.zone && computationZone?.zone?.[0]) {
    poly = toPolygon(computationZone.zone)
    simplify(poly, { tolerance: 0.001, highQuality: true, mutate: true })
  }
  // Сайти
  let list = selectSites(state).getList()
  let fields = selectSiteFields(state)
  let statusIdx = findIndex(fields, SITE_STATUS_FIELD)
  let latIdx = findIndex(fields, SITE_LAT_FIELD)
  let lngIdx = findIndex(fields, SITE_LNG_FIELD)
  const fullFilter = (statusIdx, latIdx, lngIdx) => (element) => element[statusIdx] === STATUS_ACTIVE &&
    (!poly || (
      element[lngIdx] && element[latIdx] &&
      booleanPointInPolygon(point([ element[lngIdx], element[latIdx] ]), poly)
    ))
  const siteIdx = findIndex(fields, SITE_ID_FIELD)
  const siteNameIdx = findIndex(fields, SITE_NAME_FIELD)
  const activeSites = list.filter(fullFilter(statusIdx, latIdx, lngIdx))
  const activeSitesId = activeSites.map((site) => site[siteIdx]).sort()
  // RBS
  list = selectBaseStations(state).getList()
  fields = selectBaseStationFields(state)
  statusIdx = findIndex(fields, RBS_STATUS_FIELD)
  const rbsSiteIdx = findIndex(fields, RBS_SITE_FIELD)
  const rbsNameIdx = findIndex(fields, RBS_NAME_FIELD)
  const rbsSiteNameIdx = findIndex(fields, RBS_SITE_NAME_FIELD)
  const activeRBSes = list.filter((element) => binaryIncludes(activeSitesId, element[rbsSiteIdx]))
  // Сектори
  list = selectSectors(state).getList()
  fields = selectSectorFields(state)
  statusIdx = findIndex(fields, SECTOR_STATUS_FIELD)
  latIdx = findIndex(fields, SECTOR_LAT_FIELD)
  lngIdx = findIndex(fields, SECTOR_LNG_FIELD)
  const sectorNameIdx = findIndex(fields, SECTOR_NAME_FIELD)
  const activeSectors = list.filter(fullFilter(statusIdx, latIdx, lngIdx))
  // Зберігаємо відсортовані списки назв елементів
  dispatch(setInitResources({
    resources: {
      sites: activeSites.map((item) => item[siteNameIdx]).sort(),
      rbses: activeRBSes
        .map((item) => ({ rbsName: item[rbsNameIdx], siteName: item[rbsSiteNameIdx] }))
        .sort(compareRBS),
      sectors: activeSectors.map((item) => item[sectorNameIdx]).sort(),
    },
  }))
  return true
}

// Ініціалізація вихідних даних для розрахунку
export const loadResourceForPeriod = (elementKey, selectMonths) => async (dispatch, getState) => {
  // Скидання попереднього розрахунку
  const from = new Date()
  const to = new Date()
  from.setDate(1)
  to.setDate(1)
  to.setMonth(to.getMonth() - 1)
  from.setMonth(from.getMonth() - selectMonths)
  const fromDate = from.toISOString().slice(0, 10)
  const toDate = to.toISOString().slice(0, 10)
  dispatch(setRatesResult(null))
  dispatch(setSortedRates(null))
  const state = getState()
  const { data, fields: fieldsResources, months } = selectResources(state)?.[elementKey] ?? {}
  // Перевірка на наявність необхідних даних
  if (months === selectMonths && Array.isArray(data) && Array.isArray(fieldsResources)) {
    // уже имеются необходимые исходные данные
    dispatch(setLoadingResourceStatus(null))
    return dispatch(setExecutionState(EXECUTION_STATE.CALCULATION))
  }
  // Підготовка списку елементів для запиту даних
  const { list: elements, fields } = elementKey === ELEMENTS.SITES
    ? { list: selectInitialResourceSites(state), fields: selectSiteFields(state) }
    : elementKey === ELEMENTS.RBS
      ? { list: selectInitialResourceRBSes(state), fields: selectBaseStationFields(state) }
      : elementKey === ELEMENTS.SECTORS
        ? { list: selectInitialResourceSectors(state), fields: selectSectorFields(state) }
        : {}
  if (!Array.isArray(elements) || !Array.isArray(fields)) {
    // Немає даних проєкту, скидаємо вихідні дані
    dispatch(setResources({ elementKey, resource: {}, months: null }))
    return dispatch(setLoadingResourceStatus(null))
  }
  dispatch(setLoadingResourceStatus({ current: 1 }))
  // Вибірка назв елементів для запиту даних
  let res
  switch (elementKey) {
    case ELEMENTS.SITES: {
      res = await dispatch(doLoadDataSitePeriod({ months: selectMonths, fromDate, toDate, names: elements }))
      break
    }
    case ELEMENTS.RBS: {
      res = await dispatch(doLoadDataRBSPeriod({ months: selectMonths, fromDate, toDate, names: elements }))
      break
    }
    case ELEMENTS.SECTORS: {
      res = await dispatch(doLoadDataSectorPeriod({ months: selectMonths, fromDate, toDate, names: elements }))
      break
    }
    default: {
      return dispatch(setLoadingResourceStatus(null))
    }
  }
  // Доповнення даних значеннями атрибутів із проєкту
  dispatch(setProjectAttributes(res, elementKey))
  dispatch(setLoadingResourceStatus({ current: 4 }))
  setTimeout(() => dispatch(setLoadingResourceStatus(null)), 1000)
  return dispatch(startCalculation) // setExecutionState(EXECUTION_STATE.CALCULATION))
}

export const getResourceForPeriod = (months, elementKey) => (dispatch, getState) => {
  const state = getState()
  if (months === 0) {
    switch (elementKey) {
      case ELEMENTS.SITES: {
        const fields = selectSiteFields(state)
        const initialSites = selectInitialResourceSites(state) ?? []
        const nameIdx = findIndex(fields, SITE_NAME_FIELD)
        return {
          resource: selectSites(state).getList()
            .filter((element) => binaryIncludes(initialSites, element[nameIdx])),
          fields,
        }
      }
      case ELEMENTS.RBS: {
        const fields = selectBaseStationFields(state)
        const initialRBSes = selectInitialResourceRBSes(state) ?? []
        const nameIdx = findIndex(fields, RBS_NAME_FIELD)
        const siteNameIdx = findIndex(fields, RBS_SITE_NAME_FIELD)
        return {
          resource: selectBaseStations(state).getList()
            .filter((element) => binaryIncludesCmp(
              initialRBSes,
              { rbsName: element[nameIdx], siteName: element[siteNameIdx] },
              compareRBS,
            )),
          fields,
        }
      }
      case ELEMENTS.SECTORS: {
        const fields = selectSectorFields(state)
        const initialSectors = selectInitialResourceSectors(state) ?? []
        const nameIdx = findIndex(fields, SECTOR_NAME_FIELD)
        return {
          resource: selectSectors(state).getList()
            .filter((element) => binaryIncludes(initialSectors, element[nameIdx])),
          fields,
        }
      }
      default:
    }
    return {}
  }
  const resource = elementKey === ELEMENTS.SITES
    ? selectResourceSites(state)
    : elementKey === ELEMENTS.RBS
      ? selectResourceRBS(state)
      : elementKey === ELEMENTS.SECTORS
        ? selectResourceSectors(state)
        : {}
  if (months === resource?.months) {
    return { resource: resource?.data, fields: resource?.fields }
  }
  return {}
}

export default compositeIndexSlice.reducer
