import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  JOB_ACTIVE_STATUS,
  reloadFromDefaultProject,
  reloadFromDefaultProjectUnmodified,
  updateFromAtoll,
} from '../../features/updateProject/update'
import { activeProjectId, activeProjectName, forceProjectReload } from '../../features/projects/projectsSlice'
import { startJobControl } from '../../features/synchronization/synchronizationSlice'
import { EVENT_TYPE, saveEventLog } from '../../features/eventLog/eventLogSlice'
import { useConfirm } from '../common/Confirm'
import { confirm as tConfirm } from '../common/Confirm/constants'
import {
  addTaskType,
  setTaskTypeCompleted,
  setTaskTypeFailed,
  TASK_TYPES,
} from '../../features/taskLog'
import SelectOperation from '../common/SelectOperation'

const operations = [
  {
    title: 'Synchronize with Default project',
    description: 'This routine allows you to choose manually which changes in your project will be applied to ' +
      'Default project, and which changes happened in Default project will be applied to your project. Also, in ' +
      'conflict situations (same attributes were changed in both projects) you can choose a value for each attribute ' +
      'to resolve the conflict.',
    icon: 'OpenFile',
  },
  {
    title: 'Reload from Default project',
    description: 'This routine cancels all changes in your project and reloads all the network elements from ' +
      'Default project.',
    icon: 'OpenFile',
  },
  {
    title: 'Reload unmodified data only',
    description: 'This routine loads all non-conflicting changes from Default project to your project.',
    icon: 'OpenFile',
  },
  {
    title: 'Update project from Atoll',
    description: 'This routine updates all network elements attributes from Atoll database.',
    icon: 'OpenFile',
  },
]

const SelectOperationUpdateDB = ({ onClose, onOpenSynchronize }) => {
  const dispatch = useDispatch()
  const { confirm, renderConfirm } = useConfirm()

  const projectId = useSelector(activeProjectId)
  const projectName = useSelector(activeProjectName)

  const synchronize = useCallback(() => {
    onClose()
    onOpenSynchronize()
  }, [ onOpenSynchronize, onClose ])

  const reloadDefault = useCallback(() => {
    confirm(
      async () => {
        onClose()
        dispatch(addTaskType(TASK_TYPES.reloadFromDefaultProject, projectId))
        try {
          await reloadFromDefaultProject(projectId)
          dispatch(setTaskTypeCompleted(TASK_TYPES.reloadFromDefaultProject))
          dispatch(forceProjectReload())
        } catch (error) {
          dispatch(setTaskTypeFailed(TASK_TYPES.reloadFromDefaultProject))
        }
      },
      {
        title: tConfirm.confirmation,
        messages: [
          'All changes in your project will be canceled and all data will be loaded from Default project.',
          'Then the page will be refreshed.',
        ],
        textYesBtn: tConfirm.continue,
        textNoBtn: tConfirm.cancel,
      },
    )
  }, [ confirm, projectId, dispatch, onClose ])

  const reloadUnmodified = useCallback(() => {
    confirm(
      async () => {
        onClose()
        dispatch(addTaskType(TASK_TYPES.reloadFromDPUnmodified, projectName))
        await reloadFromDefaultProjectUnmodified(projectId).then(() => {
          dispatch(setTaskTypeCompleted(TASK_TYPES.reloadFromDPUnmodified))
        }, () => {
          dispatch(setTaskTypeFailed(TASK_TYPES.reloadFromDPUnmodified))
        })
        dispatch(forceProjectReload())
      },
      {
        title: tConfirm.confirmation,
        messages: [
          'All non-conflicting changes from Default project will be loaded to your project.',
          'Then the page will be refreshed.',
        ],
        textYesBtn: tConfirm.continue,
        textNoBtn: tConfirm.cancel,
      },
    )
  }, [ confirm, projectId, projectName, dispatch, onClose ])

  const updateAtoll = useCallback(() => {
    confirm(
      async () => {
        onClose()
        const { id, status } = await updateFromAtoll(projectId)
        if (JOB_ACTIVE_STATUS.includes(status)) {
          dispatch(startJobControl())
          dispatch(addTaskType(TASK_TYPES.updateFromAtoll, undefined, id))
          dispatch(saveEventLog(EVENT_TYPE.updateProjectFromAtoll))
        }
      },
      {
        title: tConfirm.confirmation,
        messages: [ 'All data will be updated from Atoll' ],
        textYesBtn: tConfirm.continue,
        textNoBtn: tConfirm.cancel,
      },
    )
  }, [ confirm, projectId, dispatch, onClose ])

  const actions = useMemo(() => [
    synchronize,
    reloadDefault,
    reloadUnmodified,
    updateAtoll,
  ], [ synchronize, reloadDefault, reloadUnmodified, updateAtoll ])

  return (
    <>
      <SelectOperation
        className={'select-operation-update-db'}
        caption="Update DB"
        operations={operations}
        actions={actions}
        onClose={onClose}
      />
      {renderConfirm()}
    </>
  )
}

export default SelectOperationUpdateDB
